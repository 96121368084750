import { lboxHttpClient } from '@lbox-kr/components/src/utils/api'

import { WAITLIST_API_ENDPOINTS } from '@/apis/ai/waitlist/endpoint'
import type {
  RegisterForAIServiceWaitlistApiRequestPayload,
  RegisterForAIServiceApiResponse,
} from '@/apis/ai/waitlist/types'

export const registerForAIServiceWaitlist = async ({
  agreements,
}: RegisterForAIServiceWaitlistApiRequestPayload): Promise<RegisterForAIServiceApiResponse> => {
  const { data } = await lboxHttpClient.post(WAITLIST_API_ENDPOINTS.registerForAIServiceWaitlist, {
    agreements,
  })

  return data
}

export const getWaitlistStatus = async (): Promise<RegisterForAIServiceApiResponse> => {
  const { data } = await lboxHttpClient.get(WAITLIST_API_ENDPOINTS.getWaitlistStatus)

  return data
}
