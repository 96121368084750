import Image from 'next/image'
import Link from 'next/link'

import classNames from 'classnames'

import type { ServiceInfoItem } from '../$hooks/useServiceLinks'
import LfindLogo from '../images/lfind-badge.svg'

import { ENV } from '@/constants/env'

export type ServiceLinkProps = Omit<ServiceInfoItem, 'shown' | 'amplitudeEventType'>

export const ServiceLink = (props: ServiceLinkProps) => {
  const { src, href, target, alt, name, isBeta, isLfind, isNew, isPreregistration, onClick, isV1 } = props

  // v1일 경우, nextjs 페이지 이동 대신, angular 서버 페이지로 이동합니다
  // 따라서, a 태그를 사용하여 basePath(v2)가 적용되지 않도록 하고
  // 개발 환경 v1일 경우, localhost:4200 호스트로 이동합니다
  const Component = isV1 ? 'a' : Link
  const handleClick =
    ENV.NODE_ENV === 'development' && isV1
      ? (e: React.MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault()
          onClick?.(e)
          window.location.href = new URL(href, 'http://localhost:4200').toString()
        }
      : onClick

  return (
    <div className={classNames('flex flex-col items-center', 'max-w-[56px]')}>
      <Component
        href={href}
        target={target}
        className={classNames('relative', 'aspect-square', 'w-[56px]')}
        onClick={handleClick}
      >
        <Image src={src} alt={alt} className={classNames('w-full')} />
        {isBeta && <BetaBadge />}
        {isLfind && <LfindBadge />}
        {isNew && <NewBadge />}
        {isPreregistration && <PreregistrationBadge />}
      </Component>
      <span
        className={classNames(
          'text-lds2-caption1-regular tablet:text-lds2-body2-regular-trimmed',
          'mt-[8px]',
          'whitespace-nowrap'
        )}
      >
        {name}
      </span>
    </div>
  )
}

const BetaBadge = () => (
  <span
    className={classNames(
      'inline-flex items-center justify-center',
      'absolute right-[-5px] top-[-6px]',
      'px-[5px]',
      'text-lds2-caption1-medium text-white',
      'rounded-full border-[0.5px] border-zinc-900',
      'bg-zinc-800'
    )}
  >
    Beta
  </span>
)

const LfindBadge = () => (
  <span
    className={classNames(
      'inline-flex items-center justify-center',
      'absolute right-[-5px] top-[-6px]',
      'h-[18px] w-[39px]',
      'text-lds2-caption1-medium text-white',
      'rounded-full border-[0.5px] border-[#7521FF]',
      'bg-[#7521FF]'
    )}
  >
    <Image src={LfindLogo} width={26.5} height={9.5} alt="Lfind" quality={100} />
  </span>
)

const NewBadge = () => (
  <span
    className={classNames(
      'inline-flex items-center justify-center',
      'absolute right-[-5px] top-[-6px]',
      'px-[5px]',
      'text-lds2-caption1-medium text-white',
      'rounded-full border-[0.5px] border-red-700',
      'bg-red-600'
    )}
  >
    NEW
  </span>
)

const PreregistrationBadge = () => (
  <span
    className={classNames(
      'inline-flex items-center justify-center',
      'absolute right-[-5px] top-[-6px]',
      'px-[5px]',
      'text-lds2-caption1-medium text-white',
      'rounded-full border-[0.5px] border-zinc-900',
      'bg-zinc-800'
    )}
  >
    사전등록
  </span>
)
