import cn from 'classnames'

import GwangJangLogoActiveImage from './images/gwang-jang.png'
import KimAndChangLogoActiveImage from './images/kim-and-chang.png'
import SejongLogoActiveImage from './images/sejong.png'
import TaePyeongYangLogoActiveImage from './images/taepyeongyang.png'
import YulChonLogoActiveImage from './images/yulchon.png'
import type { TestimonialDataItem } from './types'

const logoImageCommonClassName = cn('max-h-full w-auto', 'transition')

export const TestimonialData: TestimonialDataItem[] = [
  {
    id: 1,
    imageSrc: KimAndChangLogoActiveImage,
    imageAltText: '김앤장 법률사무소',
    imageClassName: cn(logoImageCommonClassName, 'h-[20px]'),
    content: (
      <>
        <p>
          “기존에 사용하던 서비스에 대한 아쉬운 마음에서 엘박스를 이용해보았는데 이제는{' '}
          <strong>가장 먼저 찾는 서비스</strong>가 되었습니다.{' '}
        </p>
        <p className={cn('indent-2')}>
          하급심을 포함하여 판례가 가장 많고 원하는 <strong>판례를 신기할 정도로 잘 찾아줍니다.</strong> 특히{' '}
          <strong>폐기된 판례와 주의할 판례</strong>를 별도로 알려주어 <strong>큰 실수를 방지</strong>
          해줍니다. 하루가 다르게 서비스가 달라지고 발전하는 모습이 눈에 띄어 더욱 자주 이용하고자 합니다.”
        </p>
      </>
    ),
    contentAuthor: '최OO',
    contentAuthorOrganization: '김앤장 법률사무소 소속 변호사',
  },
  {
    id: 2,
    imageSrc: TaePyeongYangLogoActiveImage,
    imageAltText: '법무법인 태평양',
    imageClassName: cn(logoImageCommonClassName, 'h-[20px]'),
    content: (
      <p>
        “밤 늦게 의견서를 작성하다가 결론을 내리는데 꼭 확인이 필요한 <strong>하급심 판례</strong>가 있었는데,{' '}
        <strong>엘박스에서 확인하고 유레카를 외친 적이있습니다.</strong> 후배들에게도{' '}
        <strong>괜히 딴 데 가서 헤매지 말고 그냥 엘박스 쓰라고 조언</strong>하고 있습니다.”
      </p>
    ),
    contentAuthor: '김OO',
    contentAuthorOrganization: '법무법인 태평양 소속 변호사',
  },
  {
    id: 3,
    imageSrc: SejongLogoActiveImage,
    imageAltText: '법무법인 세종',
    imageClassName: cn(logoImageCommonClassName, 'h-[32px]'),
    content: (
      <p>
        “엘박스는 어느덧 국내 최고의 판례검색 사이트가 되었다고 생각합니다.{' '}
        <strong>충실한 리서치와 변론을 위해서는 필수적인 사이트</strong>라고 생각합니다. 나아가{' '}
        <strong>지속적인 기능, 편의 개선</strong>이 이뤄지고 있는 점이 특히 매력적입니다.”
      </p>
    ),
    contentAuthor: '박OO',
    contentAuthorOrganization: '법무법인 세종 소속 변호사',
  },
  {
    id: 4,
    imageSrc: GwangJangLogoActiveImage,
    imageAltText: '법무법인 광장',
    imageClassName: cn(logoImageCommonClassName, 'h-[32px]'),
    content: (
      <p>
        “<strong>양과 질에서 압도적 수준의 판례를 제공</strong>하는 엘박스는 <strong>법률실무가의 필수도구</strong>라고
        생각합니다. <strong>다른 사이트에서 열람하지 못하는 다양한 주석서</strong>도 함께 찾아볼 수 있어서 더욱 많은
        도움을 받고 있습니다.”
      </p>
    ),
    contentAuthor: '김OO',
    contentAuthorOrganization: '법무법인 광장 소속 변호사',
  },
  {
    id: 5,
    imageSrc: YulChonLogoActiveImage,
    imageAltText: '법무법인 율촌',
    imageClassName: cn(logoImageCommonClassName, 'h-[32px]'),
    content: (
      <p>
        “방대한 양의 하급심 판결까지 찾아볼 수 있어 심도 있는 사건 검토에 큰 도움이 됩니다. 특히{' '}
        <strong>사건유형, 심급, 주문유형 등의 다양한 분류 기능</strong>과 <strong>직관적인 UI</strong>는 실무적합성
        측면에서 <strong>다른 검색서비스와 비교할 수 없을 정도</strong>입니다. 서비스가 날이 갈수록 발전하고 있어
        앞으로가 더 기대됩니다.”
      </p>
    ),
    contentAuthor: '송OO',
    contentAuthorOrganization: '법무법인 율촌 소속 변호사',
  },
]
