import cn from 'classnames'

interface AdvancedSearchSelectButtonProps {
  label: string
  selected: boolean
  onClick?: VoidFunction
}

export const AdvancedSearchSelectButton = ({
  label,
  selected,
  onClick: handleClick,
}: AdvancedSearchSelectButtonProps) => {
  return (
    <button
      type="button"
      className={cn(
        'p-[8px]',
        'text-lds2-body1-medium text-zinc-500',
        'rounded-[4px]',
        'transition-colors duration-300',
        {
          'bg-white text-zinc-900': selected,
          'bg-transparent': !selected,
        }
      )}
      onClick={handleClick}
    >
      {label}
    </button>
  )
}
