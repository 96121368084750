import React from 'react'

import type { GetMouTargetResponse } from '@/apis/lbox-user/types'
import LaborAttorneyMouBanner from '@/components/header/header-type-3/top-banner/mou-banner/labor-attorney-mou-banner/LaborAttorneyMouBanner'
import LawyerMouBanner from '@/components/header/header-type-3/top-banner/mou-banner/lawyer-mou-banner/LawyerMouBanner'

type MouBannerProps = Pick<GetMouTargetResponse, 'bannerType'>

const MouBanner = ({ bannerType }: MouBannerProps) => {
  if (bannerType === 'MOU_LAWYER') {
    return <LawyerMouBanner />
  }

  if (bannerType === 'MOU_LABOR_ATTORNEY') {
    return <LaborAttorneyMouBanner />
  }

  return null
}

export default MouBanner
