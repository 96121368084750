import type { GetLawyerProfileResponse } from '@/apis/lfind/lawyer-profile/fetchers'

export class LawyerProfileContact {
  public readonly phone: string
  public readonly isPhonePublic: boolean
  public additionalPhones: { phone: string; public: boolean }[]
  public readonly email: string | null
  public readonly isEmailPublic: boolean
  public additionalEmails: { email: string; public: boolean }[]
  public company: string | null
  public companyAddress: string | null
  public companyAddressDetail: string | null
  public isCompanyAddressManual: boolean
  public companyContactNumber: string | null
  public isCompanyContactNumberPublic: boolean

  constructor({
    phone,
    phonePublic,
    additionalPhones,
    email,
    emailPublic,
    additionalEmails,
    company,
    companyAddress,
    companyAddressDetail,
    companyAddressManual,
    companyContactNumber,
    companyContactNumberPublic,
  }: GetLawyerProfileResponse['profile']['contact']) {
    this.phone = phone
    this.isPhonePublic = phonePublic
    this.additionalPhones = additionalPhones
    this.email = email
    this.isEmailPublic = emailPublic
    this.additionalEmails = additionalEmails
    this.company = company
    this.companyAddress = companyAddress
    this.companyAddressDetail = companyAddressDetail
    this.isCompanyAddressManual = companyAddressManual
    this.companyContactNumber = companyContactNumber
    this.isCompanyContactNumberPublic = companyContactNumberPublic
  }
}
