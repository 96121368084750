import Image from 'next/image'
import { useRouter } from 'next/router'

import { CSROnly } from '@lbox/shared/components'
import { LBOX_PAGE_PATH } from '@lbox/shared/constants'

import useMe from '@lbox-kr/components/src/hooks/useMe'
import { isPoliceChannel } from '@lbox-kr/components/src/utils/user/isPoliceChannel'
import { isPublicChannel } from '@lbox-kr/components/src/utils/user/isPublicChannel'

import { Banner } from './banner/Banner'
import MouBanner from './mou-banner/MouBanner'

import { useGetMouTarget } from '@/apis/lbox-user/queries'
import { useGetUserHasPlan } from '@/apis/user-has-plan/queries'
import LawSchoolProfileEhwaLogoImage from '@/assets/images/lawschool-profile-ehwa.png'
import LawSchoolProfileSeoulLogoImage from '@/assets/images/lawschool-profile-seoul.png'
import LawSchoolProfileYonseiLogoImage from '@/assets/images/lawschool-profile-yonsei.png'
import { useGetLawSchoolAuthorization } from '@/hooks/user/useGetLawSchoolAuthorization'

export const TopBanner = () => {
  const router = useRouter()

  const { isLoggedIn, isLawSchoolStudent } = useMe()
  const { data: userHasPlanData } = useGetUserHasPlan()
  const { data: lawSchoolAuthorizationData } = useGetLawSchoolAuthorization()
  const { data: mouTargetData } = useGetMouTarget({ enabled: isLoggedIn })

  const { isAuthorized: isLawSchoolAuthorized = false } = lawSchoolAuthorizationData ?? {}

  const userPlan = userHasPlanData?.plan
  const isLawSchoolPlan = userPlan === 'LAW_SCHOOL'
  const isMainPage = router.pathname === LBOX_PAGE_PATH.root

  if (!isMainPage) {
    return null
  }

  return (
    <CSROnly>
      {mouTargetData?.isValidMouPeriod && <MouBanner bannerType={mouTargetData.bannerType} />}

      {isLoggedIn && isLawSchoolStudent && (!isLawSchoolPlan || !isLawSchoolAuthorized) && !isPublicChannel() && (
        <Banner
          logoGroup={
            <>
              <Image src={LawSchoolProfileEhwaLogoImage} alt="이화여대 로스쿨" width={32} />
              <Image src={LawSchoolProfileYonseiLogoImage} alt="연세대 로스쿨" width={32} />
              <Image src={LawSchoolProfileSeoulLogoImage} alt="서울대 로스쿨" width={32} />
            </>
          }
          promotionText="로스쿨 재학 인증하고 판례를 무제한으로 열람하세요"
          onClickBannerText={() => router.push(LBOX_PAGE_PATH.pricing.index)}
        />
      )}

      {isPoliceChannel() && (
        <Banner promotionText="현재 이용 중인 사이트는 경찰관서 소속 직원만 이용할 수 있습니다. 불법적인 접속이 확인되면 민형사상 책임이 가해질 수 있으니 유의해주세요." />
      )}
    </CSROnly>
  )
}
