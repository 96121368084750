import { useQuery } from '@tanstack/react-query'

import { getLawSchoolAuthorization } from '@/apis/user/getLawSchoolAuthorization'
import { API_ENDPOINT } from '@/constants/api/endpoints'

export const LAW_SCHOOL_AUTHORIZATION_QUERY_KEY = {
  base: [API_ENDPOINT.lawSchoolAuthorization.base],
}

export const useGetLawSchoolAuthorization = () => {
  return useQuery({
    queryKey: LAW_SCHOOL_AUTHORIZATION_QUERY_KEY.base,
    queryFn: getLawSchoolAuthorization,
    select: ({ authorized }) => {
      return {
        isAuthorized: authorized ?? false,
      }
    },
  })
}
