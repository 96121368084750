import type { LboxUser } from '@lbox-kr/components/src/types/user/lboxUser'
import { lboxHttpClient } from '@lbox-kr/components/src/utils/api'

import { ENDPOINT, LBOX_USER_ENDPOINTS } from './endpoint'

import type { GetMouTargetResponse } from '@/apis/lbox-user/types'

/**
 * 쿠키 정보를 이용해 현재 유저에 대한 아주 많은 정보 조회
 */
export const getLboxUser = async () => {
  const { data } = await lboxHttpClient.get<LboxUser>(ENDPOINT)
  return data
}

export const getMouTarget = async () => {
  const { data } = await lboxHttpClient.get<GetMouTargetResponse>(LBOX_USER_ENDPOINTS.mouTarget)

  return data
}
