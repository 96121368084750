import type { ReactNode } from 'react'

import cn from 'classnames'

interface ContentCardProps {
  title: string
  description: string
  imageArea: ReactNode
}

export const ContentCard = ({ title, description, imageArea }: ContentCardProps) => {
  return (
    <div className={cn('flex flex-col', 'h-[250px]', 'p-[24px]', 'rounded-[8px]', 'bg-[rgba(255,255,255,0.50)]')}>
      <div>
        <h6 className={cn('text-lds2-heading4-semibold text-zinc-900')}>{title}</h6>
        <p className={cn('text-lds2-body2-regular text-zinc-600', 'mt-[8px]')}>{description}</p>
      </div>
      <div className={cn('flex justify-center', 'mt-auto')}>{imageArea}</div>
    </div>
  )
}
