import type { GetLawyerProfileResponse } from '@/apis/lfind/lawyer-profile/fetchers'
import { LawyerProfileConsulting } from '@/components/user/$models/LawyerProfileConsulting'
import { LawyerProfileContact } from '@/components/user/$models/LawyerProfileContact'

export class LawyerProfile {
  public readonly lawyerId: number
  public imageUri: string | null
  public introduction: string | null
  public introductionDetail: string | null
  public contact: LawyerProfileContact
  public qualificationType: string
  public turn: string
  public consulting: LawyerProfileConsulting
  public specialities: []
  public done: boolean

  constructor({
    lawyerId,
    imageUri,
    introduction,
    introductionDetail,
    contact,
    qualificationType,
    turn,
    consulting,
    specialities,
    done,
  }: GetLawyerProfileResponse['profile']) {
    this.lawyerId = lawyerId
    this.imageUri = imageUri
    this.introduction = introduction
    this.introductionDetail = introductionDetail
    this.contact = new LawyerProfileContact(contact)
    this.qualificationType = qualificationType
    this.turn = turn
    this.consulting = new LawyerProfileConsulting(consulting)
    this.specialities = specialities
    this.done = done
  }
}
