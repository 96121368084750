import type { MouseEvent } from 'react'

import type { StaticImageData } from 'next/image'

import { LBOX_AI_PAGE_PATH, LBOX_PAGE_PATH, LFIND_PAGE_PATH, V1_PAGE_PATH, externalUrl } from '@lbox/shared/constants'

import useMe from '@lbox-kr/components/src/hooks/useMe'

import { AI_SERVICE_NAME } from '../$constants/serviceName'
import CaseArchiveServiceImage from '../images/case-archive.svg'
import CaseCalendarServiceImage from '../images/case-calendar.svg'
import CommentaryServiceImage from '../images/commentary.svg'
import LawwaveServiceImage from '../images/lawwave.svg'
import LawyerProfileServiceImage from '../images/lawyer-profile.svg'
import LBOXAiServiceLogo from '../images/lbox-ai.png'
import LegalAnalyticsServiceImage from '../images/legal-analytics.svg'
import LfindServiceImage from '../images/lfind.png'
import SubstitutionServiceImage from '../images/substitution.svg'

import { useGetAIServiceWaitListStatus } from '@/apis/ai/waitlist/queries'
import { useCheckSimpleOnboardingCandidate, useGetLawyerProfile } from '@/apis/lfind/lawyer-profile/queries'
import { useGetUserHasPlan } from '@/apis/user-has-plan/queries'
import { ENV } from '@/constants/env'
import { amplitudeTrack } from '@/utils/amplitude/amplitudeTrack'

export interface ServiceInfoItem extends Pick<HTMLAnchorElement, 'href'>, Partial<Pick<HTMLAnchorElement, 'target'>> {
  src: StaticImageData
  alt: string
  name: string
  shown: boolean
  amplitudeEventType?: string
  isBeta?: boolean
  isLfind?: boolean
  isNew?: boolean
  isPreregistration?: boolean
  isV1?: boolean
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
}

export const useServiceLinks = () => {
  const user = useMe()
  const { data: userHasPlanData } = useGetUserHasPlan()

  const userId = user.isLoggedIn ? Number(user.userId) : 0
  const isLawSchoolPlan = userHasPlanData?.plan === 'LAW_SCHOOL'
  const { data: simpleOnboardingCandidate } = useCheckSimpleOnboardingCandidate({ enabled: user.isJobLawyer })
  const { data: lawyerProfile } = useGetLawyerProfile({ lawyerId: userId }, { enabled: user.isJobLawyer, retry: false })

  const { data: waitListResponseData } = useGetAIServiceWaitListStatus({
    options: {
      enabled: user.isLoggedIn,
      retry: 0,
    },
  })
  /**
   * @description
   * Production 환경에서도 테스트 해볼 수 있게 임시 주석 처리
   *  const isProduction = ENV.NEXT_PUBLIC_ENV === 'production'
   */

  const ServiceData: ServiceInfoItem[] = [
    {
      src: LBOXAiServiceLogo,
      alt: AI_SERVICE_NAME,
      name: AI_SERVICE_NAME,
      href:
        waitListResponseData?.status === 'APPROVED' ? LBOX_AI_PAGE_PATH.ai.main : LBOX_AI_PAGE_PATH.ai.landing.index,
      shown: true,
      amplitudeEventType: 'click_main_icon',
      isBeta: true,
    },
    {
      src: LawyerProfileServiceImage,
      alt: '변호사프로필',
      name: '변호사프로필',
      href: simpleOnboardingCandidate?.klawId
        ? LBOX_PAGE_PATH.lawyerProfile.simpleOnboarding
        : lawyerProfile?.onboarding?.step === 'DONE'
          ? LBOX_PAGE_PATH.lawyerProfile.index
          : lawyerProfile?.onboarding
            ? LBOX_PAGE_PATH.lawyerProfile.profileOnboarding
            : `https://${ENV.NEXT_PUBLIC_LFIND_HOST}${LFIND_PAGE_PATH.profileIntro}`,
      shown: user.isJobLawyer,
      amplitudeEventType: 'click_lawyerlbox_acquisition_icon',
      isLfind: true,
    },
    {
      src: LegalAnalyticsServiceImage,
      alt: '법률분석',
      name: '법률분석',
      href: LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.trafficCrimes.civilTraffic(),
      shown: true,
      amplitudeEventType: 'click_main_legal_analytics',
    },
    {
      src: CommentaryServiceImage,
      alt: '주석•실무서',
      name: `주석•실무서`,
      href: `${LBOX_PAGE_PATH.search.commentary}?type=목록`,
      shown: !isLawSchoolPlan,
      amplitudeEventType: 'click_main_commentary_wholelist',
    },
    {
      src: CaseCalendarServiceImage,
      alt: '사건일정관리',
      name: '사건일정관리',
      href: V1_PAGE_PATH.user.calendar.main.index,
      shown: true,
      amplitudeEventType: 'click_main_schedule_calender',
      isV1: true,
    },
    {
      src: CaseArchiveServiceImage,
      alt: '판례보관함',
      name: '판례보관함',
      href: V1_PAGE_PATH.bookmark.index,
      shown: true,
      amplitudeEventType: 'click_main_library',
      isV1: true,
    },
    {
      src: SubstitutionServiceImage,
      alt: '복대리',
      name: '복대리',
      href: V1_PAGE_PATH.substitute.preview.index,
      shown: true,
      amplitudeEventType: 'click_main_substitute',
      isV1: true,
    },
    {
      src: LawwaveServiceImage,
      alt: '로웨이브',
      name: '로웨이브',
      href: externalUrl.로웨이브,
      target: '_blank',
      shown: true,
      amplitudeEventType: 'click_main_lawwave',
    },
    {
      src: LfindServiceImage,
      alt: '엘파인드',
      name: '엘파인드',
      href: `https://${ENV.NEXT_PUBLIC_LFIND_HOST}${LFIND_PAGE_PATH.home}`,
      target: '_blank',
      shown: !user.isJobLawyer,
      amplitudeEventType: 'click_main_lfind',
      isNew: true,
    },
  ]

  function handleClickServiceLink(amplitudeEventType: ServiceInfoItem['amplitudeEventType']) {
    if (!amplitudeEventType) {
      return
    }

    amplitudeTrack(amplitudeEventType)
  }

  return {
    ServiceData,
    handleClickServiceLink,
  }
}
