import { TIME } from '@lbox/shared/constants'

import useMe from '@lbox-kr/components/src/hooks/useMe'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { WAITLIST_API_ENDPOINTS } from '@/apis/ai/waitlist/endpoint'
import { getWaitlistStatus, registerForAIServiceWaitlist } from '@/apis/ai/waitlist/fetchers'
import type { GetAIServiceWaitListStatusApiResponse } from '@/apis/ai/waitlist/types'
import type { CustomQueryOptions } from '@/types/react-query'

export const WAIT_LIST_QUERY_KEYS = {
  getWaitListStatus: [WAITLIST_API_ENDPOINTS.getWaitlistStatus],
}

/**
 * 엘박스 AI 서비스 사용 신청 mutation
 */
export const useRegisterForAIServiceWaitlist = () => {
  return useMutation({ mutationFn: registerForAIServiceWaitlist })
}

/**
 * 엘박스 AI 서비스 사용 신청 조회 query
 */
export const useGetAIServiceWaitListStatus = (
  props: {
    options?: CustomQueryOptions<GetAIServiceWaitListStatusApiResponse>
  } = {
    options: {},
  }
) => {
  const { options } = props

  const { isLoggedIn } = useMe({ staleTime: TIME['30초'] })

  return useQuery<GetAIServiceWaitListStatusApiResponse>({
    queryKey: WAIT_LIST_QUERY_KEYS.getWaitListStatus,
    queryFn: getWaitlistStatus,
    ...options,
    enabled: isLoggedIn,
    throwOnError: false,
    retry: 0,
  })
}

export const useAiServiceWaitListQueryClient = () => {
  const queryClient = useQueryClient()

  const invalidateAiServiceWaitList = () => {
    queryClient.invalidateQueries({ queryKey: WAIT_LIST_QUERY_KEYS.getWaitListStatus })
  }

  const refetchAiServiceWaitList = () => {
    queryClient.refetchQueries({ queryKey: WAIT_LIST_QUERY_KEYS.getWaitListStatus })
  }

  return {
    invalidateAiServiceWaitList,
    refetchAiServiceWaitList,
  }
}
