import { V2_PREFIX } from '@lbox/shared/constants'
import { useMatchMedia } from '@lbox/shared/hooks'

import cn from 'classnames'

import { ContentCard } from './content-card/ContentCard'

export const RestFeatures = () => {
  const isWideScreen = useMatchMedia('(min-width: 1171px)')

  return (
    <div className={cn('grid grid-flow-row grid-cols-1 gap-[24px] tablet:grid-cols-2 min-[1170px]:grid-cols-4')}>
      <ContentCard
        title="주석서"
        description="한국사법행정학회에서 제공하는 주석서 전권과 실무서(부동산경매, 채권 집행, 민사 보전)를 제공합니다."
        style={{
          // commentary-book 의 경우, svg(3.5MB) 보다 png(169KB) 파일의 사이즈가 더 작음
          backgroundImage: `url("/${V2_PREFIX}/images/index/commentary-books.png"), url("/${V2_PREFIX}/images/index/commentary-background.svg")`,
          backgroundSize: '550px, cover',
          backgroundPosition: 'center top 48px, center top -24px',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <ContentCard
        title="판례보관함"
        description="원하는 방식으로 폴더 구조를 만들어 판례를 보관하고 언제 어디서든 확인할 수 있습니다."
        style={{
          backgroundImage: `url("/${V2_PREFIX}/images/index/save-case.svg")`,
          backgroundSize: '430px',
          backgroundPosition: 'center top -24px',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <ContentCard
        title="사건 일정 관리"
        description="사건정보를 등록하면 기일과 진행 경과에 대한 알림을 카카오톡으로 빠짐없이 전달해 드립니다."
        style={{
          backgroundImage: `url("/${V2_PREFIX}/images/index/time-schedule.svg")`,
          backgroundSize: '350px',
          backgroundPosition: 'center top',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <ContentCard
        title="하이라이트"
        description="중요한 내용에 하이라이트를 표시하고 판례보관함에서도 해당 내용을 검색해 보세요."
        style={{
          backgroundImage: `url("/${V2_PREFIX}/images/index/white-dim-background.svg"), url("/${V2_PREFIX}/images/index/paper-with-highlight.svg")`,
          backgroundPosition: isWideScreen ? 'center bottom, left 64px top 36px' : 'center bottom, center top 36px',
          backgroundSize: '330px, 230px',
          backgroundRepeat: 'no-repeat',
        }}
      />
    </div>
  )
}
