import type { CSSProperties } from 'react'

import cn from 'classnames'

interface ContentCardProps {
  title: string
  description: string
  style?: CSSProperties
}

export const ContentCard = ({ title, description, style = {} }: ContentCardProps) => {
  return (
    <div
      style={style}
      className={cn(
        'relative',
        'flex flex-col',
        'h-[390px]',
        'bg-[#EFF1F5]',
        'pb-[24px]',
        'rounded-[12px]',
        'overflow-hidden'
      )}
    >
      <h6 className={cn('text-lds2-heading4-semibold', 'mb-[8px] mt-auto', 'px-[24px]')}>{title}</h6>
      <p className={cn('text-lds2-body2-regular', 'px-[24px]')}>{description}</p>
    </div>
  )
}
