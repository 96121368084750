import Link from 'next/link'

import { externalUrl } from '@lbox/shared/constants'

import type { PopoverProps } from '@lbox-kr/libra/v2'
import { Popover } from '@lbox-kr/libra/v2'
import cn from 'classnames'

import type { OnFireUserEvent } from '../../../../types/event'

interface HeaderUtilityPopoverProps {
  isOpen: boolean
  renderTarget: PopoverProps['renderTarget']
  onClickOutside?: VoidFunction
  onClickItem?: VoidFunction
  onFireUserEvent: OnFireUserEvent
}

interface MenuItem {
  label: string
  link: string
  amplitudeEventName?: string
}

const MENU_ITEMS: MenuItem[] = [
  {
    label: '이용 가이드',
    link: externalUrl.이용가이드,
    amplitudeEventName: 'click_gnb_guide',
  },
  {
    label: '고객센터',
    link: externalUrl.고객센터,
    amplitudeEventName: 'click_main_gnb_helpcenter',
  },
]

const HeaderUtilityPopover = ({
  isOpen,
  renderTarget,
  onClickOutside,
  onClickItem,
  onFireUserEvent,
}: HeaderUtilityPopoverProps) => {
  function handleClickItem(amplitudeEventName: MenuItem['amplitudeEventName']) {
    onClickItem?.()

    if (amplitudeEventName) {
      onFireUserEvent(amplitudeEventName)
    }
  }

  return (
    <Popover
      rootClassName={cn('border-solid')}
      isOpen={isOpen}
      renderTarget={renderTarget}
      body={
        <ul className={cn('text-lds2-body2-regular text-zinc-900', 'py-[8px]')}>
          {MENU_ITEMS.map(({ label, link, amplitudeEventName }, index) => (
            <Link key={index} href={link} target="_blank" onClick={() => handleClickItem(amplitudeEventName)}>
              <li className={cn('p-[8px]', 'w-[130px]', 'hover-state')}>{label}</li>
            </Link>
          ))}
        </ul>
      }
      onClickBackdrop={onClickOutside}
      onClickOutsidePopover={onClickOutside}
    />
  )
}

export default HeaderUtilityPopover
