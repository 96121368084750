import { SEARCH_LOG_LOCAL_STORAGE_KEY } from '@lbox/shared/constants'
import { webLocalStorage } from '@lbox/shared/utils'

import { postLBoxUserTrackingV2 } from '@lbox-kr/components/src/apis/client-tracking'

import type { ClickEtcEvent } from '@/apis/user/track/v2/$types/ClickEtcEvent'

interface PostClickEtcEventParams {
  type: string
  value?: string | boolean
  url?: string
  isLeafEvent?: boolean
}

export async function postClickEtcEvent({ type, value, url, isLeafEvent }: PostClickEtcEventParams) {
  const searchSessionId = webLocalStorage.getItem<string | null>(SEARCH_LOG_LOCAL_STORAGE_KEY.SEARCH_SESSION_ID, null)

  if (searchSessionId === null) {
    return
  }

  const clickSearchResultLogParams: ClickEtcEvent = {
    type,
    search_session_id: searchSessionId,
  }

  const properties: Pick<PostClickEtcEventParams, 'value' | 'url'> = {}
  if (value !== undefined) {
    properties.value = value
  }
  if (url !== undefined) {
    properties.url = url
  }

  if (Object.keys(properties).length > 0) {
    clickSearchResultLogParams.properties = properties
  }

  return postLBoxUserTrackingV2<ClickEtcEvent>({ ...clickSearchResultLogParams, isLeafEvent })
}
