import { lboxHttpClient } from '@lbox-kr/components/src/utils/api'

import { USER_ALARM_API_ENDPOINT } from '@/apis/user-alarm/endpoints'

/**
 * 쿠키 정보를 이용해 현재 유저의 알림 개수 조회
 */
export async function getUserAlarmCount() {
  const { data } = await lboxHttpClient.get(USER_ALARM_API_ENDPOINT.count)
  return data
}

export async function postUserAlarmRead() {
  const { data } = await lboxHttpClient.post(USER_ALARM_API_ENDPOINT.read)
  return data
}
