import type { ValueOf } from '@lbox/shared/types'

import { objectValues } from '@toss/utils'

export const CHAT_TYPE = {
  TEXT: 'TEXT',
  DOC_QUESTION: 'DOC_QUESTION',
  DOC_WRITING: 'DOC_WRITING',
} as const
export type ChatType = ValueOf<typeof CHAT_TYPE>
export const isChatType = (type: string): type is ChatType => {
  return objectValues(CHAT_TYPE).includes(type as ChatType)
}

export const CHAT_TYPE_KOR = {
  TEXT: '일반 질의',
  DOC_QUESTION: '문서 분석',
  DOC_WRITING: '문서 초안',
} as const

export const INVALID_CHAT_ID = -1

export const ROUTER_QUERY_PARAM_KEY = {
  채팅방_식별자: 'chatId',
  채팅방_타입: 'type',
} as const

export const DEFAULT_CHAT_TYPE = CHAT_TYPE.TEXT
