import type { FocusEvent, MouseEvent } from 'react'
import { useState } from 'react'

import type { PopoverPosition } from '@lbox-kr/libra/v1'

export const usePopover = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [popoverPosition, setPopoverPosition] = useState<PopoverPosition>({ top: 0, left: null, right: null })

  const openPopover = () => {
    setIsOpen(true)
  }

  const closePopover = () => {
    setIsOpen(false)
  }

  const setPopoverPositionFromBottomLeft = ($event: MouseEvent<HTMLElement> | FocusEvent<HTMLElement>) => {
    const { bottom, left } = $event.currentTarget.getBoundingClientRect()
    setPopoverPosition({ top: bottom, left, right: null })
  }

  const setPopoverPositionFromBottomRight = ($event: MouseEvent<HTMLElement> | FocusEvent<HTMLElement>) => {
    const { bottom, right } = $event.currentTarget.getBoundingClientRect()
    setPopoverPosition({ top: bottom, left: null, right: window.innerWidth - right })
  }

  const setPopoverPositionFromBottomLeftByElement = (element: HTMLElement) => {
    const { bottom, left } = element.getBoundingClientRect()
    setPopoverPosition({ top: bottom, left, right: null })
  }

  return {
    isPopoverOpen: isOpen,
    openPopover,
    closePopover,
    popoverPosition,
    setPopoverPositionFromBottomLeft,
    setPopoverPositionFromBottomRight,
    setPopoverPositionFromBottomLeftByElement,
  }
}
