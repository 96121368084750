import { ENV } from '@/constants/env'

/**
 * Application 실행 환경에 따라 nextjs client 의 hostname 을 반환한다
 *
 * 환경 변수를 활용하므로 server-side 에서도 문제없이 동작한다
 * 해당 환경 변수는 package.json script 에서 주입된다
 */
export const getNextJsClientHostnameByEnvironment = () => {
  switch (ENV.NEXT_PUBLIC_ENV) {
    case 'dev':
      return `https://${ENV.NEXT_PUBLIC_LBOX_API_HOST}`
    case 'staging':
      return 'https://stg.lbox.kr'
    case 'production':
      return 'https://lbox.kr'
    default:
      return 'http://localhost:3000'
  }
}
