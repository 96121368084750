import { SEARCH_LOG_LOCAL_STORAGE_KEY } from '@lbox/shared/constants'
import { generateUUID, webLocalStorage } from '@lbox/shared/utils'

import { isServer } from '@toss/utils'

import { API_ENDPOINT } from '../../constants/api/endpoint'
import { lboxHttpClient } from '../../utils/api'
import { getDeviceId } from '../../utils/fingerprint'

/**
 * @description
 * angular -> react 마이그레이션에서 사용할 clientTracking API 관련 응집 로직
 */
interface CommonScheme {
  clientId: string
  eventId: string
  pageId: string
  url: string
  prevEventId: string | null
  prevPageId: string | null
  userAgent: string
}

type EventProperties = object

export function postLBoxUserTrackingV2<T extends EventProperties>(
  eventPropertiesOrCallback: T | ((args: CommonScheme) => T)
) {
  const commonScheme = getCommonScheme()

  if (commonScheme === null) {
    return
  }

  const { clientId, eventId, pageId, url, prevEventId, prevPageId, userAgent } = commonScheme

  const eventProperties =
    typeof eventPropertiesOrCallback === 'function'
      ? eventPropertiesOrCallback(commonScheme)
      : eventPropertiesOrCallback
  /**
   * NOTE: leaf event인 경우 prev_leaf_id를 eventId로 localStorage에 저장한다.
   */
  if ('isLeafEvent' in eventProperties && eventProperties.isLeafEvent) {
    webLocalStorage.setItem(SEARCH_LOG_LOCAL_STORAGE_KEY.PREV_LEAF_ID_KEY, eventId)
  }

  if ('isLeafEvent' in eventProperties) {
    // api 요청 값에 불필요한 isLeafEvent flag 제거
    delete eventProperties.isLeafEvent
  }

  webLocalStorage.setItem(SEARCH_LOG_LOCAL_STORAGE_KEY.PREV_EVENT_ID_KEY, eventId)

  lboxHttpClient.post(API_ENDPOINT.clientTracking, eventProperties, {
    headers: {
      Clientid: clientId,
    },
    params: {
      url,
      pageId,
      eventId,
      prevEventId,
      prevPageId,
      userAgent,
    },
  })
}

const getCommonScheme = (): CommonScheme | null => {
  if (isServer()) {
    return null
  }

  const clientId = getDeviceId()
  const pageId = webLocalStorage.getItem<string | null>(SEARCH_LOG_LOCAL_STORAGE_KEY.CURRENT_PAGE_ID_KEY, null)

  return clientId && pageId
    ? {
        clientId,
        eventId: generateUUID(),
        pageId,
        url: window.location.href,
        userAgent: window.navigator.userAgent,
        prevEventId: webLocalStorage.getItem<string | null>(SEARCH_LOG_LOCAL_STORAGE_KEY.PREV_EVENT_ID_KEY, null),
        prevPageId: webLocalStorage.getItem<string | null>(SEARCH_LOG_LOCAL_STORAGE_KEY.PREV_PAGE_ID_KEY, null),
      }
    : null
}
