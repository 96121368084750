import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { ENDPOINT, LBOX_USER_ENDPOINTS } from './endpoint'
import { getMouTarget } from './fetchers'

import type { GetMouTargetResponse } from '@/apis/lbox-user/types'

const BASE_QUERY_KEY = ENDPOINT

export const LBOX_USER_QUERY_KEYS = {
  base: [BASE_QUERY_KEY],
  mouTarget: [BASE_QUERY_KEY, LBOX_USER_ENDPOINTS.mouTarget],
}

export const useGetMouTarget = (
  options?: Omit<UseQueryOptions<GetMouTargetResponse, unknown>, 'queryKey' | 'queryFn'>
) => {
  return useQuery({
    queryKey: LBOX_USER_QUERY_KEYS.mouTarget,
    queryFn: getMouTarget,
    ...options,
  })
}
