import Link from 'next/link'

import { externalUrl } from '@lbox/shared/constants'

import cn from 'classnames'

type MenuLabels = '인재채용' | '엘박스 이용약관' | '개인정보처리방침' | '운영정책' | '고객센터' | '로웨이브'

interface MenuItem {
  label: MenuLabels
  href: string
  isBold?: boolean
}

const MENU_ITEM_BY_LABEL: Record<MenuLabels, MenuItem> = {
  인재채용: {
    label: '인재채용',
    href: externalUrl.인재채용,
  },
  '엘박스 이용약관': { label: '엘박스 이용약관', href: externalUrl.이용약관 },
  개인정보처리방침: { label: '개인정보처리방침', href: externalUrl.개인정보처리방침, isBold: true },
  운영정책: { label: '운영정책', href: externalUrl.운영정책 },
  고객센터: { label: '고객센터', href: externalUrl.고객센터 },
  로웨이브: { label: '로웨이브', href: externalUrl.로웨이브 },
}

const MENU_ITEMS: MenuItem[][] = [
  [MENU_ITEM_BY_LABEL['인재채용'], MENU_ITEM_BY_LABEL['엘박스 이용약관'], MENU_ITEM_BY_LABEL['개인정보처리방침']],
  [MENU_ITEM_BY_LABEL['운영정책'], MENU_ITEM_BY_LABEL['고객센터'], MENU_ITEM_BY_LABEL['로웨이브']],
]

export const MenuList = () => {
  return (
    <div
      className={cn(
        'tablet:absolute tablet:right-0 tablet:top-0',
        'flex flex-col',
        'mt-[24px] tablet:mt-0',
        'min-w-[225px] max-w-[500px]',
        'text-lds2-body3-regular text-zinc-900',
        'whitespace-nowrap'
      )}
    >
      {MENU_ITEMS.map((row, index) => (
        <div key={index} className={cn('mt-[8px] lds2-tablet:mt-[16px]', 'w-fit')}>
          {row.map(({ label, href, isBold }) => (
            <Link
              className={cn(
                'w-[150px] max-[900px]:w-[115px] tablet:inline-block tablet:grow',
                'not-last-child:mr-[16px]',
                {
                  'font-bold': isBold,
                }
              )}
              key={label}
              href={href}
              target="_blank"
            >
              {label}
            </Link>
          ))}
        </div>
      ))}
    </div>
  )
}
