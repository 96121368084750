import type { GetLawyerProfileResponse } from '@/apis/lfind/lawyer-profile/fetchers'

export type LawyerProfileOnboardingStep = 'INTRODUCTION' | 'CASE_REGISTER' | 'SPECIALIZED' | 'DONE'

export class LawyerProfileOnboarding {
  public step: LawyerProfileOnboardingStep

  constructor({ step }: GetLawyerProfileResponse['onboarding']) {
    this.step = step
  }
}
