import { LAWYER_PROFILE_ENDPOINTS } from '@/apis/lfind/lawyer-profile/endpoints'
import type {
  Book,
  Career,
  Column,
  Education,
  LawyerProfile,
  Paper,
  RepresentativeCase,
} from '@/apis/lfind/lawyer-profile/types'
import type { SpecializedField } from '@/components/user/$constants/specializedFields'
import { lfindHttpClient } from '@/utils/api'

export type GetLawyerProfileDetailsResponse = {
  profile: LawyerProfile
  representativeCases: RepresentativeCase[]
  totalMyCase: number
  totalComment: number
  totalPublicCase: number
  totalPublicComment: number
}

export const getLawyerProfileDetails = async (lawyerId: string | number) => {
  const { data } = await lfindHttpClient.get<GetLawyerProfileDetailsResponse>(
    LAWYER_PROFILE_ENDPOINTS.details(lawyerId)
  )
  return data
}

export const getLawyerProfileMyDetails = async () => {
  const { data } = await lfindHttpClient.get<GetLawyerProfileDetailsResponse>(LAWYER_PROFILE_ENDPOINTS.base)
  return data
}

export type UpdateLawyerProfileDetailsParams = {
  lawyerId: string | number
} & {
  contact: Pick<
    LawyerProfile['contact'],
    | 'company'
    | 'companyAddress'
    | 'companyAddressDetail'
    | 'companyAddressManual'
    | 'companyContactNumber'
    | 'companyContactNumberPublic'
    | 'additionalPhones'
    | 'additionalEmails'
  >
} & Pick<
    LawyerProfile,
    | 'imageUri'
    | 'introduction'
    | 'introductionDetail'
    | 'consulting'
    | 'consultingServices'
    | 'specialities'
    | 'jobPublic'
  > & {
    careers: (Omit<Career, 'categories'> & {
      categories: number[]
    })[]
    education: (Omit<Education, 'categories'> & {
      categories: number[]
    })[]
    columns: (Omit<Column, 'categories'> & {
      categories: number[]
    })[]
    papers: (Omit<Paper, 'categories'> & {
      categories: number[]
    })[]
    books: (Omit<Book, 'categories'> & {
      categories: number[]
    })[]
  }

export const updateLawyerProfileDetails = async ({ lawyerId, ...params }: UpdateLawyerProfileDetailsParams) => {
  return lfindHttpClient.put(LAWYER_PROFILE_ENDPOINTS.details(lawyerId), params)
}

export const createLawyerProfile = () => {
  return lfindHttpClient.post<never>(LAWYER_PROFILE_ENDPOINTS.base)
}

export interface GetLawyerProfileResponse {
  privacyAgreement: boolean
  onboarding: {
    step: 'INTRODUCTION' | 'CASE_REGISTER' | 'SPECIALIZED' | 'DONE'
  }
  profile: {
    lawyerId: number
    imageUri: string | null
    introduction: string | null
    introductionDetail: string | null
    contact: {
      phone: string
      phonePublic: boolean
      additionalPhones: { phone: string; public: boolean }[]
      email: string
      emailPublic: boolean
      additionalEmails: { email: string; public: boolean }[]
      company: string | null
      companyAddress: string | null
      companyAddressDetail: string | null
      companyAddressManual: boolean
      companyContactNumber: string | null
      companyContactNumberPublic: boolean
    }
    qualificationType: string
    turn: string
    consulting: {
      startHour: number
      startMinute: number
      endHour: number
      endMinute: number
    }
    specialities: []
    done: boolean
  }
  lawyerMyCaseInfos: {
    title: string
    docId: string
    court: string
    caseName: string
  }[]
}

export interface GetLawyerProfileParams {
  lawyerId: number
}

export const getLawyerProfile = async ({ lawyerId }: GetLawyerProfileParams) => {
  const { data } = await lfindHttpClient.get<GetLawyerProfileResponse>(LAWYER_PROFILE_ENDPOINTS.byId(lawyerId))
  return data
}

interface UpdateLawyerProfileParamsBase {
  lawyerId: number
}

export interface UpdateLawyerProfileParamsIntroduction
  extends UpdateLawyerProfileParamsBase,
    Partial<Pick<GetLawyerProfileResponse['profile'], 'imageUri' | 'introduction' | 'introductionDetail'>> {
  type: 'INTRODUCTION'
  contact?: Partial<GetLawyerProfileResponse['profile']['contact']>
  consulting?: GetLawyerProfileResponse['profile']['consulting']
}

export interface UpdateLawyerProfileParamsSpeciality extends UpdateLawyerProfileParamsBase {
  type: 'SPECIALITY'
  codes: SpecializedField['code'][]
}

export const updateLawyerProfile = (
  params: UpdateLawyerProfileParamsIntroduction | UpdateLawyerProfileParamsSpeciality
) => {
  return lfindHttpClient.put<never>(LAWYER_PROFILE_ENDPOINTS.base, params)
}

export const updateLawyerProfileDone = () => {
  return lfindHttpClient.post<never>(LAWYER_PROFILE_ENDPOINTS.done)
}

export interface GetSimpleOnboardingCheckResponse {
  klawId: string | null
}

export const getSimpleOnboardingCheck = async () => {
  const { data } = await lfindHttpClient.get<GetSimpleOnboardingCheckResponse>(
    LAWYER_PROFILE_ENDPOINTS.simpleOnboarding
  )
  return data
}

export type ProceedSimpleOnboardingParams = Omit<UpdateLawyerProfileDetailsParams, 'lawyerId'>

export const proceedSimpleOnboarding = async (params: ProceedSimpleOnboardingParams) => {
  return lfindHttpClient.post<never>(LAWYER_PROFILE_ENDPOINTS.simpleOnboarding, params)
}

export interface SaveKLawRawProfileParams {
  lawyerId: number
  profile: string
}

export const saveKLawRawProfile = ({ lawyerId, profile }: SaveKLawRawProfileParams) => {
  return lfindHttpClient.post<never>(LAWYER_PROFILE_ENDPOINTS.klaw(lawyerId), { raw: profile })
}

export const requestUpdateProfile = ({ uri }: { uri: string }) => {
  return lfindHttpClient.post<never>(LAWYER_PROFILE_ENDPOINTS.updateRequest, { uri })
}
