import type { UseMutationOptions, UseQueryOptions } from '@tanstack/react-query'
import { useMutation, useQuery } from '@tanstack/react-query'

import { LAWYER_PROFILE_ENDPOINTS } from '@/apis/lfind/lawyer-profile/endpoints'
import type {
  GetLawyerProfileDetailsResponse,
  GetLawyerProfileResponse,
  GetSimpleOnboardingCheckResponse,
  ProceedSimpleOnboardingParams,
  SaveKLawRawProfileParams,
  UpdateLawyerProfileDetailsParams,
  UpdateLawyerProfileParamsIntroduction,
  UpdateLawyerProfileParamsSpeciality,
} from '@/apis/lfind/lawyer-profile/fetchers'
import {
  createLawyerProfile,
  getLawyerProfile,
  getLawyerProfileDetails,
  getLawyerProfileMyDetails,
  getSimpleOnboardingCheck,
  proceedSimpleOnboarding,
  requestUpdateProfile,
  saveKLawRawProfile,
  updateLawyerProfile,
  updateLawyerProfileDetails,
  updateLawyerProfileDone,
} from '@/apis/lfind/lawyer-profile/fetchers'
import { LawyerMyCase } from '@/components/user/$models/LawyerMyCase'
import { LawyerProfile } from '@/components/user/$models/LawyerProfile'
import { LawyerProfileOnboarding } from '@/components/user/$models/LawyerProfileOnboarding'

export const LAWYER_PROFILE_QUERY_KEYS = {
  all: ['/lawyer/profile'],
  byId: (lawyerId: string | number) => [...LAWYER_PROFILE_QUERY_KEYS.all, LAWYER_PROFILE_ENDPOINTS.byId.name, lawyerId],
  details: (lawyerId: string | number) => [
    ...LAWYER_PROFILE_QUERY_KEYS.all,
    LAWYER_PROFILE_ENDPOINTS.details.name,
    lawyerId,
  ],
  myDetails: () => [...LAWYER_PROFILE_QUERY_KEYS.all, LAWYER_PROFILE_ENDPOINTS.base],
  simpleOnboarding: () => [...LAWYER_PROFILE_QUERY_KEYS.all, LAWYER_PROFILE_ENDPOINTS.simpleOnboarding],
}

export const useGetLawyerProfileDetails = (
  lawyerId: string | number,
  options?: Omit<UseQueryOptions<GetLawyerProfileDetailsResponse>, 'queryKey' | 'queryFn'>
) => {
  return useQuery({
    queryKey: LAWYER_PROFILE_QUERY_KEYS.details(lawyerId),
    queryFn: () => getLawyerProfileDetails(lawyerId),
    ...options,
  })
}

export const useGetLawyerProfileMyDetails = (
  options?: Omit<UseQueryOptions<GetLawyerProfileDetailsResponse>, 'queryKey' | 'queryFn'>
) => {
  return useQuery({
    queryKey: LAWYER_PROFILE_QUERY_KEYS.myDetails(),
    queryFn: getLawyerProfileMyDetails,
    ...options,
  })
}

export const useUpdateLawyerProfileDetails = (
  options?: UseMutationOptions<unknown, unknown, UpdateLawyerProfileDetailsParams>
) => {
  return useMutation<unknown, unknown, UpdateLawyerProfileDetailsParams>({
    mutationFn: updateLawyerProfileDetails,
    ...options,
  })
}

export const useCreateLawyerProfile = (options?: UseMutationOptions) => {
  return useMutation({ mutationFn: createLawyerProfile, ...options })
}

interface UseGetLawyerProfileParams {
  lawyerId: number
}

interface UseGetLawyerProfileReturn {
  privacyAgreement: boolean
  onboarding: LawyerProfileOnboarding
  profile: LawyerProfile
  myCase: LawyerMyCase[]
}

export const useGetLawyerProfile = (
  { lawyerId }: UseGetLawyerProfileParams,
  options?: Omit<UseQueryOptions<GetLawyerProfileResponse, unknown, UseGetLawyerProfileReturn>, 'queryKey' | 'queryFn'>
) => {
  return useQuery({
    queryKey: LAWYER_PROFILE_QUERY_KEYS.byId(lawyerId),
    queryFn: () => getLawyerProfile({ lawyerId }),
    select: ({ privacyAgreement, onboarding, profile, lawyerMyCaseInfos }) => ({
      privacyAgreement,
      onboarding: new LawyerProfileOnboarding(onboarding),
      profile: new LawyerProfile(profile),
      myCase: lawyerMyCaseInfos.map((lawyerMyCaseInfo) => new LawyerMyCase(lawyerMyCaseInfo)),
    }),
    ...options,
  })
}

export const useUpdateLawyerProfile = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof updateLawyerProfile>>,
    unknown,
    UpdateLawyerProfileParamsIntroduction | UpdateLawyerProfileParamsSpeciality,
    unknown
  >
) => {
  return useMutation({ mutationFn: updateLawyerProfile, ...options })
}

export const useUpdateLawyerProfileDone = (options?: UseMutationOptions) => {
  return useMutation({ mutationFn: updateLawyerProfileDone, ...options })
}

export const useCheckSimpleOnboardingCandidate = (
  options?: Omit<UseQueryOptions<GetSimpleOnboardingCheckResponse>, 'queryKey' | 'queryFn'>
) => {
  return useQuery<GetSimpleOnboardingCheckResponse>({
    queryKey: LAWYER_PROFILE_QUERY_KEYS.simpleOnboarding(),
    queryFn: getSimpleOnboardingCheck,
    ...options,
  })
}

export const useProceedSimpleOnboarding = (
  options?: UseMutationOptions<unknown, unknown, ProceedSimpleOnboardingParams>
) => {
  return useMutation<unknown, unknown, ProceedSimpleOnboardingParams>({
    mutationFn: proceedSimpleOnboarding,
    ...options,
  })
}

export const useSaveKLawRawProfile = (options?: UseMutationOptions<unknown, unknown, SaveKLawRawProfileParams>) => {
  return useMutation<unknown, unknown, SaveKLawRawProfileParams>({
    mutationFn: saveKLawRawProfile,
    ...options,
  })
}

export const useRequestUpdateProfile = () => {
  return useMutation({ mutationFn: requestUpdateProfile })
}
