import { PLAN_NAME_KOR } from '@lbox/shared/constants'

export const getUserPlanDisplayName = (planName: string) => {
  if (!planName) {
    return PLAN_NAME_KOR.free
  }

  if (planName.includes(PLAN_NAME_KOR.standard)) {
    return PLAN_NAME_KOR.standard
  }

  if (planName.includes(PLAN_NAME_KOR.business)) {
    return PLAN_NAME_KOR.business
  }

  if (planName.includes(PLAN_NAME_KOR['non-profit'])) {
    return PLAN_NAME_KOR.public
  }

  return planName.replace('플랜', '')
}

export const isRestrictedToGetMembershipInfo = (planName: string) => {
  return planName.includes(PLAN_NAME_KOR.business)
}
