import cn from 'classnames'

import { useServiceLinks } from './$hooks/useServiceLinks'
import { ServiceLink } from './service-link/ServiceLink'

export const ServiceLinks = () => {
  const { ServiceData, handleClickServiceLink } = useServiceLinks()

  return (
    <div
      className={cn(
        'grid grid-flow-col-dense justify-between gap-[16px_0] tablet:justify-center tablet:gap-[16px_40px]',
        'max-[360px]:justify-items-center',
        'max-lds2-tablet:w-[500px] max-lds2-tablet:justify-items-center max-lds2-tablet:px-[40px]',
        'max-lds2-tablet:grid-flow-row max-lds2-tablet:grid-cols-3'
      )}
      data-testid="service-links"
    >
      {ServiceData.map(({ shown, amplitudeEventType, onClick, ...rest }, index) =>
        shown ? (
          <ServiceLink
            key={index}
            onClick={(e) => {
              handleClickServiceLink(amplitudeEventType)
              onClick?.(e)
            }}
            {...rest}
          />
        ) : null
      )}
    </div>
  )
}
