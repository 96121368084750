import Image from 'next/image'

import { LBOX_AI_PAGE_PATH, V2_PREFIX, externalUrl } from '@lbox/shared/constants'

import { Button } from '@lbox-kr/libra/v2'
import classNames from 'classnames'

import { LBOX_AI_ICON_SRC } from '@/components/ai/shared/$constants/icon'

const LBOXAIIntroduction = () => {
  const handleClickCTA = () => {
    window.open(`/${V2_PREFIX}${LBOX_AI_PAGE_PATH.ai.landing.index}`)
  }

  return (
    <div
      className={classNames(
        'flex flex-col justify-between gap-[32px] lds2-tablet:flex-row',
        'w-full',
        'p-[32px]',
        'rounded-[12px]',
        'bg-text-primary-on-brand',
        'brand-gradient-shadow'
      )}
    >
      <div className={classNames('flex flex-col items-center gap-[32px] lds2-tablet:items-start')}>
        <Image
          src={LBOX_AI_ICON_SRC.LOGO.TEXT_AND_SYMBOL_LOGO}
          alt="엘박스 AI 서비스 로고"
          className={classNames('w-[80px] lds2-tablet:w-[150px]')}
          priority
          width={170}
          height={50}
        />

        <div
          className={classNames(
            'flex flex-col items-center gap-[8px] lds2-tablet:items-start',
            'text-center lds2-tablet:text-left'
          )}
        >
          <h3 className={classNames('text-lds2-heading4-semibold lds2-tablet:text-lds2-heading1-bold')}>
            질문 한 번으로 끝내는 법률 리서치
          </h3>
          <p
            className={classNames('text-lds2-body2-regular text-text-secondary lds2-tablet:text-lds2-heading4-medium')}
          >
            리서치의 미래를 엘박스 AI에서 만나보세요.
          </p>
        </div>

        <div>
          <Button buttonType="filled" size="large" label="자세히 살펴보기" onClick={handleClickCTA} />
        </div>
      </div>
      <div className={classNames('flex items-center justify-center')}>
        <video
          loop
          autoPlay
          muted
          playsInline
          className={classNames('rounded-[8px]', 'aspect-[16/10]', 'w-full max-w-[500px] desktop:w-[479px]')}
          width={479}
        >
          <source src={`${externalUrl.staticAssetsCloudFrontUrl}/videos/ai/introduction.webm`} />
        </video>
      </div>
    </div>
  )
}
export default LBOXAIIntroduction
