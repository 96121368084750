import { useEffect } from 'react'
import Lottie from 'react-lottie'

import { withErrorBoundary } from '@lbox/shared/components'

import cn from 'classnames'

import { useTopFeatures } from './$hooks/useTopFeatures'
import { AdvancedSearchSelectButton } from './advanced-search-select-button/AdvancedSearchSelectButton'
import { GrayBox } from './gray-box/GrayBox'
import AiCaseAnimation from './json/ai-cases.json'
import ExactMatchSearchAnimation from './json/exact-match-search.json'
import ExcludeSearchAnimation from './json/exclude-search.json'

const TopFeatures = () => {
  const { selectedAdvancedSearchFeature, selectAdvancedSearch } = useTopFeatures()

  function selectNextFeature() {
    if (selectedAdvancedSearchFeature === '완전일치') {
      selectAdvancedSearch('제외어')
    } else {
      selectAdvancedSearch('완전일치')
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      selectNextFeature()
    }, 5000)

    return () => {
      clearInterval(intervalId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAdvancedSearchFeature])

  return (
    <div className={cn('mb-[24px] tablet:mb-[100px]')}>
      <h3 className={cn('text-lds2-heading3-semibold tablet:text-lds2-heading1-bold', 'mb-[24px] tablet:mb-[48px]')}>
        20,000명의 변호사님들이 엘박스를 <br className={cn('hidden tablet:block')} /> 선택한 이유를 알아보세요.
      </h3>
      <div className={cn('grid grid-flow-row grid-cols-1 gap-[24px]')}>
        <GrayBox>
          <div className={cn('flex flex-col items-center', 'pb-[64px] pt-[40px]', 'h-[400px]')}>
            <h3
              className={cn(
                'text-lds2-heading4-semibold text-black tablet:text-lds2-heading1-bold',
                'mb-[24px] tablet:mb-0'
              )}
            >
              고급검색기능
            </h3>
            <div className={cn('grid grid-flow-col gap-[16px]', 'tablet:mt-[24px]')}>
              <AdvancedSearchSelectButton
                label="제외어"
                selected={selectedAdvancedSearchFeature === '제외어'}
                onClick={() => selectAdvancedSearch('제외어')}
              />
              <AdvancedSearchSelectButton
                label="완전일치"
                selected={selectedAdvancedSearchFeature === '완전일치'}
                onClick={() => selectAdvancedSearch('완전일치')}
              />
            </div>
            <div
              className={cn(
                'ml-[30px]',
                'overflow-x-clip',
                'tablet:w-[600px]',
                'mt-[40px]',
                'max-h-[85px] min-h-[85px]'
              )}
            >
              {selectedAdvancedSearchFeature === '제외어' && (
                <Lottie
                  isClickToPauseDisabled
                  options={{
                    animationData: ExcludeSearchAnimation,
                    autoplay: true,
                    loop: true,
                    rendererSettings: {
                      preserveAspectRatio: 'xMinYMax slice',
                    },
                  }}
                />
              )}
              {selectedAdvancedSearchFeature === '완전일치' && (
                <Lottie
                  isClickToPauseDisabled
                  options={{
                    animationData: ExactMatchSearchAnimation,
                    autoplay: true,
                    loop: true,
                    rendererSettings: {
                      preserveAspectRatio: 'xMinYMax slice',
                    },
                  }}
                />
              )}
            </div>
            <p
              className={cn(
                'text-center text-lds2-body2-regular text-zinc-900 tablet:text-lds2-heading4-medium',
                'mx-[45px] mt-[40px] tablet:mt-[26px]'
              )}
            >
              {selectedAdvancedSearchFeature === '제외어' &&
                '‘환매‘가 들어간 문서 중 ‘징발’을 제외한 결과를 보여줍니다.'}
              {selectedAdvancedSearchFeature === '완전일치' && (
                <span>
                  “도시개발“이 들어간 <br className={cn('block tablet:hidden')} /> 결과만을 보여줍니다.
                </span>
              )}
            </p>
          </div>
        </GrayBox>
        <GrayBox>
          <div className={cn('tablet:relative', 'flex flex-col tablet:flex-row', 'h-[470px] tablet:h-[500px]')}>
            <div className={cn('flex flex-col justify-center', 'p-[24px_24px_0] tablet:p-[0_48px]')}>
              <h3 className={cn('text-lds2-heading4-semibold text-black tablet:text-lds2-heading1-bold')}>
                AI 유사판례
              </h3>
              <p className={cn('text-lds2-body2-regular text-zinc-900 tablet:text-lds2-heading3-medium', 'my-[8px]')}>
                현재 보고 있는 판례와 사실관계가 가장 유사한 판례를 AI가 자동으로 추천하여 리서치 시간을 60% 이상 단축할
                수 있습니다.
              </p>
              <p
                className={cn(
                  'tablet:absolute tablet:bottom-[48px]',
                  'text-lds2-caption1-regular text-zinc-500 tablet:text-lds2-body1-medium'
                )}
              >
                *스탠다드 플랜 3개월 이상 이용 변호사 500명 대상 설문조사 결과
              </p>
            </div>
            <div
              className={cn(
                'max-w-[288px] tablet:min-w-[400px] min-[1170px]:min-w-[500px]',
                'mx-auto mt-auto tablet:mt-0'
              )}
            >
              <Lottie
                isClickToPauseDisabled
                options={{
                  animationData: AiCaseAnimation,
                  autoplay: true,
                  loop: true,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
              />
            </div>
          </div>
        </GrayBox>
      </div>
    </div>
  )
}

// NOTE: 간헐적으로 node를 못 찾는 에러 해소가 되지 않아서, 지역 error boundary 추가
export default withErrorBoundary(TopFeatures, <></>)
