import Image from 'next/image'
import { useRouter } from 'next/router'

import { LBOX_PAGE_PATH } from '@lbox/shared/constants'

import { Button } from '@lbox-kr/libra/v2'
import cn from 'classnames'

import { ContentCard } from './content-card/ContentCard'
import { GrayGradientBox } from './gray-gradient-box/GrayGradientBox'
import BusinessPlanClientImage from './images/business-plan-client.png'
import LawyerProfileImage from './images/lawyer-profile.png'

import CIOLogoImage from '@/assets/images/cio.png'
import KogasLogoImage from '@/assets/images/kogas.png'
import LawSchoolProfileEhwaImage from '@/assets/images/lawschool-profile-ehwa.png'
import LawSchoolProfileSeoulImage from '@/assets/images/lawschool-profile-seoul.png'
import LawSchoolProfileYonseiImage from '@/assets/images/lawschool-profile-yonsei.png'
import SeoulCityLogoImage from '@/assets/images/seoul.png'
import { amplitudeTrack } from '@/utils/amplitude/amplitudeTrack'

export const PlanGuide = () => {
  const router = useRouter()

  return (
    <div className={cn('w-full')}>
      <GrayGradientBox>
        <div className={cn('flex flex-col items-center')}>
          <div>
            <h5 className={cn('text-center text-lds2-heading3-semibold text-zinc-900 tablet:text-lds2-heading1-bold')}>
              최적의 플랜을 선택하고 <br />
              지금 바로 엘박스를 <br className={cn('tablet:hidden')} />
              이용해 보세요.
            </h5>
          </div>
          <div className={cn('w-[180px]', 'mt-[24px]')}>
            <Button
              type="button"
              buttonType="outlined"
              label="요금제 비교"
              width="full"
              onClick={() => {
                router.push(LBOX_PAGE_PATH.pricing.index)
                amplitudeTrack('click_main_pricing')
              }}
            />
          </div>
          <div
            className={cn(
              'grid grid-flow-row grid-cols-1 gap-[32px] tablet:grid-cols-2 min-[1170px]:grid-cols-4',
              'mt-[48px]'
            )}
          >
            <ContentCard
              title="스탠다드"
              description="첫 이용시 7일 무료 혜택을 제공해드려요."
              imageArea={
                <div className={cn('min-w-[192px] max-w-[232px]')}>
                  <Image src={LawyerProfileImage} alt="변호사" />
                </div>
              }
            />
            <ContentCard
              title="비즈니스"
              description="회사/기관의 경우 구성원 간 판례보관함 공유를 통해 협업을 강화할 수 있습니다."
              imageArea={
                <div className={cn('max-w-[192px]')}>
                  <Image src={BusinessPlanClientImage} alt="비즈니스 플랜 고객사" />
                </div>
              }
            />
            <ContentCard
              title={new Date().getFullYear() >= 2024 ? '퍼블릭' : '비영리단체'}
              description="비영리단체라면 IP 기반으로 편리하게 이용할 수 있습니다."
              imageArea={
                <div className={cn('grid grid-flow-col grid-rows-1 gap-[16px]', 'pb-[16px]')}>
                  <Image src={KogasLogoImage} alt="서울가스공사" width={48} />
                  <Image src={SeoulCityLogoImage} alt="서울시" width={48} />
                  <Image src={CIOLogoImage} alt="고위공직자범죄수사처" width={48} />
                </div>
              }
            />
            <ContentCard
              title="로스쿨"
              description="로스쿨 재학생이라면 인증 후 판례를 무제한으로 열람할 수 있습니다."
              imageArea={
                <div className={cn('grid grid-flow-col grid-rows-1 gap-[16px]', 'pb-[16px]')}>
                  <Image src={LawSchoolProfileEhwaImage} alt="이화여대 로스쿨" width={48} />
                  <Image src={LawSchoolProfileYonseiImage} alt="연세대 로스쿨" width={48} />
                  <Image src={LawSchoolProfileSeoulImage} alt="서울대 로스쿨" width={48} />
                </div>
              }
            />
          </div>
        </div>
      </GrayGradientBox>
    </div>
  )
}
