import { createHttpClient, webLocalStorage } from '@lbox/shared/utils'

import {
  LAW_SCHOOL_CAN_USE_LBOX_AI_KEY,
  LAW_SCHOOL_LBOX_AI_POPUP_KEY,
  LFIND_POPUP_STORAGE_KEY,
  SRP_INTERPRETATION_BANNER_NOT_SHOWN_KEY,
} from '@lbox-kr/components/src/constants/localStorage'
import { serverTokenStorage } from '@lbox-kr/components/src/utils/asyncHooks'
import { isClient, isServer } from '@toss/utils'

import { ENV } from '@/constants/env'
import { FINGERPRINT_DEVICE_ID_LOCALSTORAGE_KEY } from '@/constants/fingerprint'
import {
  LAWYER_EXAM_13TH_EVENT_POPUP_NO_SHOWN_KEY,
  LBOX_AI_POPUP_NOT_SHOWN_KEY,
  TOKEN_FROM_LBOX_CLIENT,
  TOP_BANNER_NOT_SHOWN_UNTIL_KEY,
} from '@/constants/localStorage'
import { SESSION_STORAGE_KEYS } from '@/constants/sessionStorage'
import LBoxCookieStorageUtils from '@/utils/cookieStorage'

export const logoutProcess = () => {
  /** DESC : 로그아웃 로직 따라 모든 쿠키를 삭제시켜준다. -> 추후 이 부분이 문제가 될 시 유저 관련 정보만 삭제하도록 수정 */
  LBoxCookieStorageUtils.clear()

  /** DESC : 로그아웃 로직 따라 세션 스토리지의 nonProfitOrgInfo 데이터를 삭제한다. */
  sessionStorage.removeItem(SESSION_STORAGE_KEYS.nonProfitOrgInfo)

  /** DESC : 로그아웃 로직 따라 로컬 스토리지의 특정 데이터 삭제*/
  webLocalStorage.clearExcept([
    FINGERPRINT_DEVICE_ID_LOCALSTORAGE_KEY,
    TOP_BANNER_NOT_SHOWN_UNTIL_KEY,
    LAWYER_EXAM_13TH_EVENT_POPUP_NO_SHOWN_KEY,
    SRP_INTERPRETATION_BANNER_NOT_SHOWN_KEY,
    LBOX_AI_POPUP_NOT_SHOWN_KEY,
    LFIND_POPUP_STORAGE_KEY,
    LAW_SCHOOL_CAN_USE_LBOX_AI_KEY,
    LAW_SCHOOL_LBOX_AI_POPUP_KEY,
  ])
}

const LFIND_API_HOST = ENV.NEXT_PUBLIC_LFIND_API_HOST || 'lfind.kr'

/**
 * 엘파인드 API 요청을 보낼 때 사용하는 httpClient.
 */
const lfindHttpClient = createHttpClient({
  baseURL: `https://${LFIND_API_HOST}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
})

lfindHttpClient.interceptors.request.use((config) => {
  if (isClient()) {
    const token = LBoxCookieStorageUtils.get(TOKEN_FROM_LBOX_CLIENT)
    token && (config.headers.Authorization = `Bearer ${token}`)
  }
  if (isServer()) {
    const token = serverTokenStorage.getStore()
    token && (config.headers.Authorization = `Bearer ${token}`)
  }
  return config
})

export { lfindHttpClient }
