import { useMutation, useQuery } from '@tanstack/react-query'

import { USER_ALARM_API_ENDPOINT } from '@/apis/user-alarm/endpoints'
import { getUserAlarmCount, postUserAlarmRead } from '@/apis/user-alarm/fetchers'
import { getHasLoginToken } from '@/utils/getHasLoginToken'

export const USER_ALARM_QUERY_KEYS = {
  base: [USER_ALARM_API_ENDPOINT.base],
  count: () => [...USER_ALARM_QUERY_KEYS.base, USER_ALARM_API_ENDPOINT.count],
  read: () => [...USER_ALARM_QUERY_KEYS.base, USER_ALARM_API_ENDPOINT.read],
}

export const useGetUserAlarmCount = () => {
  const hasLoginToken = getHasLoginToken()

  return useQuery<number>({
    queryKey: USER_ALARM_QUERY_KEYS.count(),
    queryFn: getUserAlarmCount,
    enabled: hasLoginToken,
  })
}

export const usePostUserAlarmRead = () => {
  return useMutation({ mutationKey: USER_ALARM_QUERY_KEYS.read(), mutationFn: postUserAlarmRead })
}
