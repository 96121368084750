import type { LawSchoolAuthorization } from '@lbox-kr/components/src/apis/law-school-authorization/fetchers'
import { lboxHttpClient } from '@lbox-kr/components/src/utils/api'

import { API_ENDPOINT } from '@/constants/api/endpoints'

export const getLawSchoolAuthorization = async () => {
  const { data } = await lboxHttpClient.get<LawSchoolAuthorization>(API_ENDPOINT.lawSchoolAuthorization.base)

  return data
}
