import useMe from '@lbox-kr/components/src/hooks/useMe'
import type { UseQueryOptions } from '@tanstack/react-query'
import { useMutation, useQuery } from '@tanstack/react-query'

import { USER_HAS_PLAN_API_ENDPOINT } from '@/apis/user-has-plan/endpoints'
import {
  getMembershipInfo,
  getUserHasPlan,
  getUserHasPlanLast,
  postUserHasPlan,
  postUserHasPlanRegistered,
} from '@/apis/user-has-plan/fetchers'
import type { GetUserHasPlanResponse } from '@/apis/user-has-plan/types'
import { getUserPlanDisplayName, isRestrictedToGetMembershipInfo } from '@/apis/user-has-plan/utils'

// query key
const BASE_QUERY_KEY = USER_HAS_PLAN_API_ENDPOINT.base

export const USER_HAS_PLAN_QUERY_KEYS = {
  base: [BASE_QUERY_KEY],
  membershipInfo: [BASE_QUERY_KEY, USER_HAS_PLAN_API_ENDPOINT.membershipInfo],
  last: [BASE_QUERY_KEY, USER_HAS_PLAN_API_ENDPOINT.last],
} as const

// hooks
export const useGetUserHasPlan = (options?: Omit<UseQueryOptions<GetUserHasPlanResponse>, 'queryKey' | 'queryFn'>) => {
  return useQuery<GetUserHasPlanResponse>({
    queryKey: USER_HAS_PLAN_QUERY_KEYS.base,
    queryFn: getUserHasPlan,
    ...options,
  })
}

export const useGetMembershipInfo = () => {
  const { data: userHasPlan } = useGetUserHasPlan()
  const { isLoggedIn } = useMe()

  const userPlanDisplayName = userHasPlan?.planName ? getUserPlanDisplayName(userHasPlan.planName) : ''

  return useQuery({
    queryKey: USER_HAS_PLAN_QUERY_KEYS.membershipInfo,
    queryFn: getMembershipInfo,
    enabled: isLoggedIn && !isRestrictedToGetMembershipInfo(userPlanDisplayName),
    select: (res) => ({
      ...res,
      isFreeTrial: res.paymentType === 'FREE_TRIAL',
    }),
  })
}

export const usePostUserHasPlan = () => {
  return useMutation({ mutationFn: postUserHasPlan })
}

export const useGetUserHasPlanLast = () => {
  return useQuery({ queryKey: USER_HAS_PLAN_QUERY_KEYS.last, queryFn: getUserHasPlanLast })
}

export const usePostUserHasPlanRegistered = () => {
  return useMutation({ mutationFn: postUserHasPlanRegistered })
}
