import cn from 'classnames'

import { TextHighlight } from './TextHighlight'
import { TextRotatorItem } from './TextRotatorItem'

export const TextRotator = () => {
  return (
    <div className={cn('overflow-y-hidden', 'max-h-[96px] min-h-[96px] tablet:max-h-[72px] tablet:min-h-[72px]')}>
      <TextRotatorItem>
        <FirstRotatorItem />
      </TextRotatorItem>
      <TextRotatorItem>
        <TextHighlight>판례</TextHighlight>
        <p>&nbsp;보유 수&nbsp;</p>
        <TextHighlight>1위</TextHighlight>
      </TextRotatorItem>
      <TextRotatorItem>
        <TextHighlight>변호사</TextHighlight>&nbsp;회원 수&nbsp;<TextHighlight>1위</TextHighlight>
      </TextRotatorItem>
      <TextRotatorItem>
        <TextHighlight>100% 전문</TextHighlight>&nbsp;공개 판례
      </TextRotatorItem>
      <TextRotatorItem>
        <FirstRotatorItem />
      </TextRotatorItem>
    </div>
  )
}

const FirstRotatorItem = () => {
  return (
    <>
      <div className={cn('hidden tablet:block')}>
        <p>
          한국사법행정학회 <TextHighlight>주석서 전권</TextHighlight> 제공{' '}
        </p>
      </div>
      <div className={cn('block tablet:hidden')}>
        <p>한국사법행정학회</p>
        <p>
          <TextHighlight>주석서 전권</TextHighlight> 제공
        </p>
      </div>
    </>
  )
}
