import type { StrictPropsWithChildren } from '@lbox/shared/types'

import cn from 'classnames'

interface GrayBoxProps {
  className?: string
}

export const GrayBox = ({ className = '', children }: StrictPropsWithChildren<GrayBoxProps>) => {
  return <div className={cn('bg-[#EFF1F5]', 'rounded-[12px]', className)}>{children}</div>
}
