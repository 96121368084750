import type { ReactElement } from 'react'
import { useEffect } from 'react'

import { useRouter } from 'next/router'

import { LBOX_PAGE_PATH } from '@lbox/shared/constants'
import { shuffle, isPublicChannel } from '@lbox/shared/utils'

import { getLboxUser } from '@lbox-kr/components/src/apis/lbox-user/fetchers'
import { getPromotionsPeriods } from '@lbox-kr/components/src/apis/promotions/fetchers'
import { PROMOTIONS_QUERY_KEYS } from '@lbox-kr/components/src/apis/promotions/queries'
import { getUserHasPlanInServerSide } from '@lbox-kr/components/src/apis/user/getUserHasPlan'
import ChallengeStatusHeroTitle from '@lbox-kr/components/src/components/ai-kkujun-challenge/ChallengeStatusHeroTitle'
import { Footer } from '@lbox-kr/components/src/components/footer/Footer'
import { SearchBottomSheet } from '@lbox-kr/components/src/components/search-bar/search-bottom-sheet/SearchBottomSheet'
import SearchBar from '@lbox-kr/components/src/components/search-bar/SearchBar'
import useMe from '@lbox-kr/components/src/hooks/useMe'
import { Button } from '@lbox-kr/libra/v2'
import { dehydrate, QueryClient, useQuery } from '@tanstack/react-query'
import { useIsMounted } from '@toss/react'
import cn from 'classnames'
import { atom, useAtom } from 'jotai'
import type { GetServerSideProps } from 'next'

import { LBOX_USER_QUERY_KEYS } from '@/apis/lbox-user/queries'
import { getIp } from '@/apis/route-handler/fetchers'
import { USER_HAS_PLAN_QUERY_KEYS } from '@/apis/user-has-plan/queries'
import { FeatureIntroduction } from '@/components/index/feature-introduction/FeatureIntroduction'
import LBOXAIIntroduction from '@/components/index/lbox-ai-introduction/LBOXAIIntroduction'
import { PlaceholderSearchBarMobile } from '@/components/index/placeholder-search-bar-mobile/PlaceholderSearchBarMobile'
import { PlanGuide } from '@/components/index/plan-guide/PlanGuide'
import { ServiceLinks } from '@/components/index/service-links/ServiceLinks'
import { Testimonial } from '@/components/index/testimonial/Testimonial'
import { TextRotator } from '@/components/index/text-rotator/TextRotator'
import { HeaderType3Layout } from '@/components/layouts/HeaderType3Layout'
import { BANNER_LIST } from '@/components/shared/home-banner/$constants'
import type { ImageItem } from '@/components/shared/home-banner/banner-slider/BannerSlider'
import HomeBanner from '@/components/shared/home-banner/HomeBanner'
import HomePopup from '@/components/shared/home-popup/HomePopup'
import Head from '@/components/shared/meta/head/Head'
import ZendeskChatScript from '@/components/shared/zendesk-chat/ZendeskChatScript'
import type { NextPageWithLayout } from '@/types/app'
import { amplitudeTrack } from '@/utils/amplitude/amplitudeTrack'
import { withLoginAuth } from '@/utils/auth'
import { getOgUrlContent } from '@/utils/meta'
import { withToken } from '@/utils/serverside'

export const isSearchBottomSheetOpenAtom = atom(false)

const Home: NextPageWithLayout<{ bannerList: ImageItem[] }> = ({ bannerList }) => {
  const router = useRouter()
  const isMounted = useIsMounted()
  const { isLoggedIn } = useMe()

  const [isOpenSearchBottomSheet, setIsOpenSearchBottomSheet] = useAtom(isSearchBottomSheetOpenAtom)

  function openSearchBottomSheet() {
    setIsOpenSearchBottomSheet(true)
  }

  function closeSearchBottomSheet() {
    setIsOpenSearchBottomSheet(false)
  }

  useEffect(() => {
    amplitudeTrack('view_main')
  }, [])

  return (
    <>
      <Head title="LBOX - 판례 검색은 엘박스에서!" ogTitle="LBOX - 판례 검색은 엘박스에서!">
        <meta property="og:url" content={getOgUrlContent(LBOX_PAGE_PATH.root)} />
      </Head>

      <main className={cn('flex justify-center', 'px-[16px]', 'break-keep')}>
        <div className={cn('flex flex-col items-center', 'w-[1170px] max-w-full')}>
          <section
            className={cn(
              'flex flex-col items-center',
              'w-full',
              {
                'h-[clamp(560px,calc(var(--custom-vh,1vh)*100-var(--gnb-height-mobile)-var(--main-page-client-logo-height)),calc(var(--custom-vh,1vh)*100-var(--gnb-height-mobile)-var(--main-page-client-logo-height)))]':
                  !isLoggedIn,
                'tablet:h-[clamp(700px,calc(var(--custom-vh,1vh)*80-var(--gnb-height-tablet-desktop)),calc(var(--custom-vh,1vh)*80-var(--gnb-height-tablet-desktop)))]':
                  !isLoggedIn,
                'py-[80px] tablet:pb-[80px] tablet:pt-[160px]': !isLoggedIn,
              },
              {
                'h-[clamp(750px,calc(var(--custom-vh-static,1vh)*100-var(--gnb-height-mobile)),calc(var(--custom-vh-static,1vh)*100-var(--gnb-height-mobile)))]':
                  isLoggedIn,
                'tablet:h-[clamp(780px,calc(var(--custom-vh,1vh)*100-var(--gnb-height-tablet-desktop)),calc(var(--custom-vh,1vh)*100-var(--gnb-height-tablet-desktop)))]':
                  isLoggedIn,
                'pb-[72px] pt-[100px] tablet:pt-[200px]': isLoggedIn,
              }
            )}
          >
            <div className="pb-4 empty:hidden">
              <ChallengeStatusHeroTitle />
            </div>

            {!isLoggedIn && (
              <>
                <TextRotator />
                <Button
                  className="mt-6"
                  label="가입하고 7일 무료 이용하기"
                  type="button"
                  buttonType="filled"
                  size="large"
                  onClick={() => {
                    amplitudeTrack('click_main_hero_call_to_action')
                    router.push(LBOX_PAGE_PATH.register.index())
                  }}
                />
              </>
            )}
            {/* 검색창 */}
            <div className={cn('lds2-tablet:min-h-[84px]', { 'mt-[88px] lds2-tablet:mt-[80px]': !isLoggedIn })}>
              <PlaceholderSearchBarMobile onClick={openSearchBottomSheet} />
              <SearchBar
                rootClassName={cn('hidden lds2-tablet:block')}
                autoFocus
                size="l"
                onFireUserEvent={amplitudeTrack}
                router={router}
                disabled={!isMounted}
              />
            </div>
            {/* 서비스 링크 */}
            {isLoggedIn && (
              <div className={cn('flex justify-center', 'w-full', 'mt-[48px] tablet:mt-[32px]')}>
                <ServiceLinks />
              </div>
            )}
            {/* 배너 슬라이더 */}
            <HomeBanner bannerList={bannerList} />
          </section>

          {!isLoggedIn && (
            <div className={cn('w-full', 'pb-[72px] pt-[106px]')}>
              <LBOXAIIntroduction />
            </div>
          )}

          {!isLoggedIn && (
            <div className={cn('w-full')}>
              <section className={cn('w-full')}>
                <Testimonial />
              </section>
              <section className={cn('w-full')}>
                <FeatureIntroduction />
              </section>
              <section className={cn('flex flex-col items-center', 'w-full', 'pb-[80px]')}>
                <PlanGuide />
              </section>
            </div>
          )}

          <Footer />
        </div>
      </main>

      {/**
       * @description main 화면 팝업 영역
       */}
      <HomePopup />

      {isOpenSearchBottomSheet && (
        <SearchBottomSheet
          isOpen={isOpenSearchBottomSheet}
          onClickPreviousButton={closeSearchBottomSheet}
          onFireUserEvent={amplitudeTrack}
        />
      )}

      <ZendeskChatScript />

      {/* 공공채널은 임의로 IP 변경시 유료플랜을 사용할 수 없습니다. 사용자가 IP를 확인 있도록 해서 문제 원인을 안내 받을수 있도록 합니다. */}
      {isPublicChannel() && <IPDisplay />}
    </>
  )
}

Home.getLayout = (page: ReactElement) => {
  return <HeaderType3Layout>{page}</HeaderType3Layout>
}

export const getServerSideProps: GetServerSideProps = withToken(
  withLoginAuth(async ({ req, res, isLoggedIn }) => {
    // 엘파인드 iframe에서 사용하는 쿠키
    res.setHeader('set-cookie', `lbox-visitor=${Date.now()}; SameSite=None; Secure; path=/;`)

    const bannerList = shuffle(BANNER_LIST)

    const queryClient = new QueryClient()

    if (!isLoggedIn) {
      return {
        props: {
          dehydratedState: dehydrate(queryClient),
          bannerList,
        },
      }
    }

    await Promise.allSettled([
      queryClient.prefetchQuery({
        queryKey: LBOX_USER_QUERY_KEYS.base,
        queryFn: getLboxUser,
      }),

      queryClient.prefetchQuery({
        queryKey: USER_HAS_PLAN_QUERY_KEYS.base,
        queryFn: () => getUserHasPlanInServerSide(req.headers['x-forwarded-for']),
      }),

      queryClient.prefetchQuery({
        queryKey: PROMOTIONS_QUERY_KEYS.periods('LAWYER_GROWTH_20000'),
        queryFn: () => getPromotionsPeriods('LAWYER_GROWTH_20000'),
      }),
    ])

    return {
      props: {
        dehydratedState: dehydrate(queryClient),
        bannerList,
      },
    }
  })
)

export default Home

function IPDisplay() {
  const query = useQuery({ queryKey: ['ip'], queryFn: getIp })
  if (query.isLoading) {
    return null
  }
  return (
    <div className="fixed bottom-8 right-[92px] z-50 rounded bg-white bg-opacity-50 px-2 py-1 text-lds2-body3-regular">
      접속IP : {query.data?.ipAddress}
    </div>
  )
}
