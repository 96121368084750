import type { StrictPropsWithChildren } from '@lbox/shared/types'

import cn from 'classnames'

export const TextHighlight = ({ children }: StrictPropsWithChildren) => {
  return (
    <span className={cn('bg-[linear-gradient(180deg,#5FC5F0_0%,#0282EA_50%)] bg-clip-text', 'text-transparent')}>
      {children}
    </span>
  )
}
