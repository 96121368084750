import cn from 'classnames'

import { RestFeatures } from './rest-features/RestFeatures'
import TopFeatures from './top-features/TopFeatures'

export const FeatureIntroduction = () => {
  return (
    <div className={cn('my-[40px] tablet:mb-[164px] tablet:mt-[144px]')}>
      <TopFeatures />

      <RestFeatures />
    </div>
  )
}
