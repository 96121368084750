import type { StrictPropsWithChildren } from '@lbox/shared/types'

import cn from 'classnames'

export const TextRotatorItem = ({ children }: StrictPropsWithChildren) => {
  return (
    <div
      className={cn(
        'flex items-center justify-center',
        'h-[96px] tablet:h-[72px]',
        'text-[30px] font-bold leading-[48px] tracking-[-0.66px] tablet:text-lds2-display2-bold',
        'animate-text-rotate',
        'text-center'
      )}
    >
      {children}
    </div>
  )
}
