import { externalUrl } from '@lbox/shared/constants'

import docQuestionChatIcon from './$assets/doc-question-chat-icon.svg'
import docWritingChatIcon from './$assets/doc-writing-chat-icon.svg'
import textChatIcon from './$assets/text-chat-icon.svg'

import { CHAT_TYPE } from '@/components/ai/shared/$constants/route'

export const LBOX_AI_ICON_SRC = {
  LOGO: {
    SYMBOL_LOGO: `${externalUrl.staticAssetsCloudFrontUrl}/images/ai/lbox-ai-symbol-logo.png`,
    TEXT_AND_SYMBOL_LOGO: `${externalUrl.staticAssetsCloudFrontUrl}/images/ai/lbox-ai-text-symbol-logo.png`,
    LBOX_LOGO: `${externalUrl.staticAssetsCloudFrontUrl}/images/ai/lbox-logo.svg`,
  },
  CHAT: {
    [CHAT_TYPE.TEXT]: textChatIcon,
    [CHAT_TYPE.DOC_QUESTION]: docQuestionChatIcon,
    [CHAT_TYPE.DOC_WRITING]: docWritingChatIcon,
  },
  FILE: {
    PDF: `${externalUrl.staticAssetsCloudFrontUrl}/images/ai/service/pdf-logo.svg`,
    HWP: `${externalUrl.staticAssetsCloudFrontUrl}/images/ai/service/hwp-logo.svg`,
    MS: `${externalUrl.staticAssetsCloudFrontUrl}/images/ai/service/ms-word-logo.svg`,
    SHEET: `${externalUrl.staticAssetsCloudFrontUrl}/images/ai/service/xlsx-csv-logo.svg`,
    PPT: `${externalUrl.staticAssetsCloudFrontUrl}/images/ai/service/ppt-logo.png`,
    IMAGE: `${externalUrl.staticAssetsCloudFrontUrl}/images/ai/service/img-logo.svg`,
    TXT: `${externalUrl.staticAssetsCloudFrontUrl}/images/ai/service/txt-logo.svg`,
  },
}
