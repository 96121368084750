import { LBOX_PAGE_PATH, V2_PREFIX } from '@lbox/shared/constants'
import { useLocalStorage } from '@lbox/shared/hooks'

import { useGetPromotionsPeriods } from '@lbox-kr/components/src/apis/promotions/queries'
import { EventModal } from '@lbox-kr/libra/v2'
import classNames from 'classnames'

import Lawyer20KCelebrationImage from '@/components/shared/home-popup/lawyer-20k-celebration/lawyer-20k-celebration.png'
import { amplitudeTrack } from '@/utils/amplitude/amplitudeTrack'

const Lawyer20KCelebrationPopup = () => {
  const [isClosed, setIsClosed] = useLocalStorage('lawyer-20k-celebration-home-popup', false)
  const { data: promotionPeriods } = useGetPromotionsPeriods({
    promotionName: 'LAWYER_GROWTH_20000',
  })

  const isPromotionPeriod = Boolean(promotionPeriods?.data.isEventInProgress)

  const close = () => setIsClosed(true)

  if (isClosed || !isPromotionPeriod) {
    return null
  }

  return (
    <EventModal
      eventTracker={amplitudeTrack}
      eventName="변호사 2만명 이벤트"
      aspectRatio="1:1"
      imageSrc={Lawyer20KCelebrationImage.src}
      imageAlt="변호사 2만명 이벤트"
      isOpen
      position="top"
      showsCloseButton
      closeIconClassName={classNames('group-enabled:fill-foreground-white')}
      onClose={close}
      onClick={() => {
        window.open(`/${V2_PREFIX}${LBOX_PAGE_PATH.event.lawyer['20k-celebration']}`)
      }}
    />
  )
}

export default Lawyer20KCelebrationPopup
