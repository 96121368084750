import type { StrictPropsWithChildren } from '@lbox/shared/types'

import useMe from '@lbox-kr/components/src/hooks/useMe'

import HeaderType3 from '@/components/header/header-type-3/HeaderType3'

/**
 * @description
 * 메인페이지에서 사용하는 layout 입니다.
 */
export const HeaderType3Layout = ({ children }: StrictPropsWithChildren) => {
  const { isLoggedIn, isJobLawyer, name } = useMe()

  return (
    <>
      <HeaderType3 isLoggedIn={isLoggedIn} isJobLawyer={isJobLawyer} name={name} />
      {children}
    </>
  )
}
