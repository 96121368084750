import { webSessionStorage } from '@lbox/shared/utils'

import { lboxHttpClient } from '@lbox-kr/components/src/utils/api'

import { IP_HISTORIES_REQUEST_HEADER_KEY, IP_HISTORIES_SESSION_STORAGE_KEY } from '@/apis/user-has-plan/constants'
import { USER_HAS_PLAN_API_ENDPOINT } from '@/apis/user-has-plan/endpoints'
import type {
  GetMembershipInfoResponse,
  GetUserHasPlanLastResponse,
  GetUserHasPlanResponse,
  PostUserHasPlanRegisteredRequest,
  PostUserHasPlanRequest,
  UserHasPlan,
} from '@/apis/user-has-plan/types'

export const getMembershipInfo = async () => {
  const { data } = await lboxHttpClient.get<GetMembershipInfoResponse>(USER_HAS_PLAN_API_ENDPOINT.membershipInfo)

  return data
}

export const getUserHasPlan = async () => {
  const { data } = await lboxHttpClient.get<GetUserHasPlanResponse>(USER_HAS_PLAN_API_ENDPOINT.base)

  return data
}

export const postUserHasPlan = async (params: PostUserHasPlanRequest) => {
  const { data } = await lboxHttpClient.post<UserHasPlan>(USER_HAS_PLAN_API_ENDPOINT.base, undefined, {
    params,
  })

  return data
}

export const getUserHasPlanLast = async () => {
  const { data } = await lboxHttpClient.get<GetUserHasPlanLastResponse>(USER_HAS_PLAN_API_ENDPOINT.last)

  return data
}

export const postUserHasPlanRegistered = async (params?: PostUserHasPlanRegisteredRequest) => {
  const { data } = await lboxHttpClient.post<UserHasPlan>(USER_HAS_PLAN_API_ENDPOINT.registered, undefined, {
    params,
  })

  return data
}

export const getIpHistoriesHeader = async () => {
  const memoizedValue = webSessionStorage.getItem<string | null>(IP_HISTORIES_SESSION_STORAGE_KEY, null)

  const ipHistories =
    memoizedValue ??
    (await lboxHttpClient.get<UserHasPlan>(USER_HAS_PLAN_API_ENDPOINT.base)).headers[IP_HISTORIES_REQUEST_HEADER_KEY]

  webSessionStorage.setItem(IP_HISTORIES_SESSION_STORAGE_KEY, ipHistories)

  return ipHistories
}
