import { LBOX_PAGE_PATH, V2_PREFIX } from '@lbox/shared/constants'

import { getNextJsClientHostnameByEnvironment } from '@/utils/getClientHostName'

export const getOgUrlContent = (path: string) => {
  if (path === LBOX_PAGE_PATH.root) {
    return `${getNextJsClientHostnameByEnvironment()}/${V2_PREFIX}`
  }

  return `${getNextJsClientHostnameByEnvironment()}/${V2_PREFIX}${path}`
}
