import type { MouseEvent } from 'react'
import { useRef } from 'react'

import { useRouter } from 'next/router'

import { IconCaretDown } from '@lbox/shared/components'
import { LBOX_PAGE_PATH, V2_PREFIX } from '@lbox/shared/constants'
import { logger } from '@lbox/shared/utils'

import GuestButtons from '@lbox-kr/components/src/components/header/shared/guest-buttons/GuestButtons'
import HeaderUtilityPopover from '@lbox-kr/components/src/components/header/shared/header-utility-popover/HeaderUtilityPopover'
import MenuDrawerAndPopover from '@lbox-kr/components/src/components/header/shared/menu-drawer-and-popover/MenuDrawerAndPopover'
import NotificationPopover from '@lbox-kr/components/src/components/header/shared/notification-popover/NotificationPopover'
import UserButtons from '@lbox-kr/components/src/components/header/shared/user-buttons/UserButtons'
import { LBoxLogo } from '@lbox-kr/components/src/components/lbox-logo/LBoxLogo'
import { MyCaseUploadModal } from '@lbox-kr/components/src/components/my-case-upload-modal/MyCaseUploadModal'
import { Button } from '@lbox-kr/libra/v2'
import { useBooleanState } from '@toss/react'
import cn from 'classnames'
import { Userpilot } from 'userpilot'

import { TopBanner } from './top-banner/TopBanner'

import { useGetUserAlarmCount, usePostUserAlarmRead } from '@/apis/user-alarm/queries'
import { useGetUserHasPlan } from '@/apis/user-has-plan/queries'
import { usePopover } from '@/hooks/usePopover'
import { amplitudeTrack } from '@/utils/amplitude/amplitudeTrack'

interface HeaderType3Props {
  isJobLawyer: boolean
  isLoggedIn: boolean
  name: string
}

/**
 * @description
 * 메인페이지에서 사용하는 layout 입니다.
 */
const HeaderType3 = ({ isJobLawyer, isLoggedIn, name }: HeaderType3Props) => {
  const utilityButtonRef = useRef<HTMLButtonElement | null>(null)

  const router = useRouter()
  const { refetch: refreshNotificationsCount } = useGetUserAlarmCount()

  const { data: userHasPlanData } = useGetUserHasPlan()
  const { mutateAsync: readNotifications } = usePostUserAlarmRead()

  const [isOpenUtilityPopover, openUtilityPopup, closeUtilityPopup] = useBooleanState(false)
  const [isMyCaseUploadModalOpen, openMyCaseUploadModal, closeMyCaseUploadModal] = useBooleanState(false)

  const hasUserPlan = userHasPlanData?.plan === 'MEMBERSHIP'

  const {
    isPopoverOpen: isNotificationPopoverOpen,
    openPopover: openNotificationPopover,
    closePopover: closeNotificationPopover,
    popoverPosition: notificationPopoverPosition,
    setPopoverPositionFromBottomRight: setNotificationPopoverPositionFromBottomRight,
  } = usePopover()

  const {
    isPopoverOpen: isMenuDrawerAndPopoverOpen,
    openPopover: openProfileDrawerAndPopover,
    closePopover: closeMenuDrawerAndPopover,
    popoverPosition: profilePopoverPosition,
    setPopoverPositionFromBottomRight: setProfilePopoverPositionFromBottomRight,
  } = usePopover()

  const handleClickNotificationButton = (event: MouseEvent<HTMLButtonElement>) => {
    amplitudeTrack('click_gnb_alarm')
    setNotificationPopoverPositionFromBottomRight(event)
    openNotificationPopover()
    // 알림 읽음 처리 후 알림 개수 재조회
    readNotifications().then(() => refreshNotificationsCount?.())
  }

  const handleClickProfileButton = (event: MouseEvent<HTMLButtonElement>) => {
    setProfilePopoverPositionFromBottomRight(event)
    openProfileDrawerAndPopover()
  }

  const handleClick유용한자료 = () => {
    amplitudeTrack('click_main_gnb_resources')
    openUtilityPopup()
  }

  const handleClickPricing = () => {
    amplitudeTrack('click_main_gnb_pricing')
    router.push(LBOX_PAGE_PATH.pricing.index)
  }

  const handleClickRegisterMyCaseButton = () => {
    amplitudeTrack('click_gnb_caseregister')
    openMyCaseUploadModal()
    Userpilot.trigger('q8R_Dq8uG9')
  }

  return (
    <>
      <TopBanner />

      <header
        className={cn(
          'flex items-center',
          'w-screen max-w-full',
          'px-[16px] tablet:px-[32px]',
          'h-[72px]',
          'justify-between gap-x-[0]',
          'gap-x-[20px]',
          'bg-white',
          'tablet:border-b-zinc-300'
        )}
      >
        {/* 헤더 좌측 영역 */}
        <div className={cn('align-center flex')}>
          <a href={`/${V2_PREFIX}`}>
            <LBoxLogo />
          </a>

          <div className={cn('hidden grid-flow-col items-center gap-[8px] tablet:grid', 'ml-[24px]')}>
            <Button
              ref={utilityButtonRef}
              buttonType="ghost"
              size="medium"
              label="고객지원"
              rightIcon={<IconCaretDown size={20} weight="bold" className="text-zinc-500" />}
              onClick={handleClick유용한자료}
            />

            {!hasUserPlan && <Button label="요금제" buttonType="ghost" size="medium" onClick={handleClickPricing} />}
          </div>
        </div>

        {/* 헤더 우측 영역 */}
        {!isLoggedIn && (
          <GuestButtons type="type3" onFireUserEvent={amplitudeTrack} onClickProfileButton={handleClickProfileButton} />
        )}

        {isLoggedIn && (
          <UserButtons
            type="type3"
            isJobLawyer={isJobLawyer}
            name={name}
            onClickRegisterMyCaseButton={handleClickRegisterMyCaseButton}
            onClickNotificationButton={handleClickNotificationButton}
            onClickProfileButton={handleClickProfileButton}
          />
        )}
      </header>

      {/* 고객지원 팝오버 */}
      <HeaderUtilityPopover
        isOpen={isOpenUtilityPopover}
        renderTarget={utilityButtonRef.current}
        onClickOutside={closeUtilityPopup}
        onClickItem={closeUtilityPopup}
        onFireUserEvent={amplitudeTrack}
      />

      {/* 내 판결문 등록 업로드 Modal */}
      <MyCaseUploadModal
        isOpen={isMyCaseUploadModalOpen}
        onClose={closeMyCaseUploadModal}
        onFireUserEvent={amplitudeTrack}
        onFireErrorLog={logger.error}
      />

      {/* 알림 팝오버 */}
      {isLoggedIn && isNotificationPopoverOpen && (
        <NotificationPopover
          isOpen={isNotificationPopoverOpen}
          handleClickBackdrop={closeNotificationPopover}
          top={notificationPopoverPosition.top}
          left={notificationPopoverPosition.left}
          right={notificationPopoverPosition.right}
          onFireUserEvent={amplitudeTrack}
        />
      )}

      {/* 메뉴 드로어 겸 팝오버 */}
      <MenuDrawerAndPopover
        isOpen={isMenuDrawerAndPopoverOpen}
        handleClickBackdrop={closeMenuDrawerAndPopover}
        onFireUserEvent={amplitudeTrack}
        {...profilePopoverPosition}
      />
    </>
  )
}

export default HeaderType3
