import { IconMagnifyingGlass } from '@lbox/shared/components'

import { useIsMounted } from '@toss/react'
import cn from 'classnames'

interface PlaceholderSearchBarMobileProps {
  onClick?: VoidFunction
}

export const PlaceholderSearchBarMobile = ({ onClick: handleClickRoot }: PlaceholderSearchBarMobileProps) => {
  const isMounted = useIsMounted()

  return (
    <button
      type="button"
      className={cn([
        'relative',
        'flex items-center',
        'h-[58px] w-[calc(100vw-32px)]',
        'px-[20px] py-[12px]',
        'rounded-[999px] border border-brand-600',
        'cursor-pointer',
        'lds2-tablet:hidden',
      ])}
      data-testid="placeholder-search-bar-mobile"
      disabled={!isMounted}
      onClick={handleClickRoot}
    >
      <IconMagnifyingGlass size={24} />
      <p className={cn('ml-[16px] flex-1 text-left text-lds2-body2-regular text-zinc-400')}>
        사건번호, 키워드를 입력하세요.
      </p>
    </button>
  )
}
