import React from 'react'

import Link from 'next/link'

import { externalUrl } from '@lbox/shared/constants'

import cn from 'classnames'

const LawyerMouBanner = () => {
  return (
    <div
      className={cn(
        'flex flex-col items-center justify-center gap-x-[20px] gap-y-[12px] lds2-tablet:flex-row',
        'p-[12px_16px] lds2-tablet:p-[12px_32px]',
        'bg-[linear-gradient(90deg,_#016EFF_0%,_#90CCFF_100%)]'
      )}
    >
      <span className={cn('text-center text-lds2-body3-medium text-white')}>
        엘박스와 MOU가 체결된 지회 소속 변호사님께 <br className={cn('block lds2-tablet:hidden')} /> 스탠다드 플랜 할인
        혜택을 제공합니다.
      </span>

      <Link
        type="button"
        target="_blank"
        href={externalUrl.소속_인증_방법_안내}
        className={cn(
          'p-[9px_10px]',
          'text-lds2-body3-medium-trimmed text-brand-600',
          'rounded-[4px] bg-background-primary'
        )}
      >
        소속 지회 인증하기
      </Link>
    </div>
  )
}

export default LawyerMouBanner
