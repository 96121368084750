import type { GetLawyerProfileResponse } from '@/apis/lfind/lawyer-profile/fetchers'

export class LawyerProfileConsulting {
  public startHour: number
  public startMinute: number
  public endHour: number
  public endMinute: number

  constructor({ startHour, startMinute, endHour, endMinute }: GetLawyerProfileResponse['profile']['consulting']) {
    if (startHour < 0 || startHour > 23) {
      throw new RangeError('시간은 0~23 사이의 값이어야 합니다.')
    }

    if (startMinute < 0 || startMinute > 59) {
      throw new RangeError('분은 0~59 사이의 값이어야 합니다.')
    }

    if (endHour < 0 || endHour > 23) {
      throw new RangeError('시간은 0~23 사이의 값이어야 합니다.')
    }

    if (endMinute < 0 || endMinute > 59) {
      throw new RangeError('분은 0~59 사이의 값이어야 합니다.')
    }

    this.startHour = startHour
    this.startMinute = startMinute
    this.endHour = endHour
    this.endMinute = endMinute
  }
}
