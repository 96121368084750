import { useEffect, useRef, useState } from 'react'

import Image from 'next/image'

import { useImpressionRef } from '@toss/impression-area'
import cn from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { useAtomValue } from 'jotai'

import { TestimonialData } from './constants'
import { LogoButton } from './logo-button/LogoButton'
import TestimonialText from './testimonial-text/TestimonialText'

import { isSearchBottomSheetOpenAtom } from '@/pages/index'

export const ContentWithTransition = () => {
  const [selectedLogoId, setSelectedLogoId] = useState(1)
  const [isLogoContainerInView, setIsLogoContainerInView] = useState(false)
  const isSearchBottomSheetOpen = useAtomValue(isSearchBottomSheetOpenAtom)
  const logoButtonsRef = useRef<(HTMLButtonElement | null)[]>([])

  const logoContainerRef = useImpressionRef({
    onImpressionStart: () => setIsLogoContainerInView(true),
    onImpressionEnd: () => setIsLogoContainerInView(false),
    areaThreshold: 1,
  })

  const currentTestimonialData = TestimonialData.find(({ id }) => selectedLogoId === id)
  const { content = '', contentAuthor = '', contentAuthorOrganization = '' } = currentTestimonialData ?? {}

  function scrollSelectedButtonIntoView() {
    if (!isLogoContainerInView || isSearchBottomSheetOpen) {
      return
    }

    const buttonElements = logoButtonsRef.current
    if (buttonElements.length > 0) {
      buttonElements[selectedLogoId - 1]?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
    }
  }

  function handleClickLogo(logoId: number) {
    setSelectedLogoId(logoId)
  }

  function handleTestimonialAnimationComplete() {
    const totalClientLogoCount = TestimonialData.length

    if (selectedLogoId === totalClientLogoCount) {
      setSelectedLogoId(1)
      return
    }

    setSelectedLogoId((previousSelectedLogoId) => previousSelectedLogoId + 1)
  }

  useEffect(() => {
    scrollSelectedButtonIntoView()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLogoId])

  return (
    <div className={cn('flex flex-col tablet:flex-row', 'w-full')}>
      <div
        ref={logoContainerRef}
        className={cn('flex tablet:flex-col', 'min-w-[155px]', 'tablet:mr-[32px]', 'overflow-x-scroll no-scrollbar')}
      >
        {TestimonialData.map(({ id, imageSrc, imageAltText, imageClassName }, index) => (
          <LogoButton
            key={index}
            ref={(el) => {
              logoButtonsRef.current[index] = el
            }}
            onClick={() => handleClickLogo(id)}
          >
            <Image
              src={imageSrc}
              alt={imageAltText}
              className={cn(imageClassName, 'transition-opacity', {
                'opacity-30': selectedLogoId !== id,
                'opacity-100': selectedLogoId === id,
              })}
            />
            {/* progress indicator */}
            {selectedLogoId === id && (
              <motion.div
                initial={{
                  width: 0,
                }}
                animate={{
                  width: '100%',
                }}
                transition={{
                  ease: 'linear',
                  duration: '8',
                }}
                className={cn('z-[-1]', 'absolute left-0 top-0', 'bg-[#EFF1F5]', 'h-full')}
                onAnimationComplete={handleTestimonialAnimationComplete}
              />
            )}
          </LogoButton>
        ))}
      </div>
      <div className={cn('relative', 'mt-[16px]')}>
        <AnimatePresence>
          {TestimonialData.map(
            ({ id }) =>
              selectedLogoId === id && (
                <motion.div
                  key={`testimonial-text-${selectedLogoId}`}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className={cn('absolute top-0')}
                >
                  <TestimonialText
                    content={content}
                    contentAuthor={contentAuthor}
                    contentAuthorOrganization={contentAuthorOrganization}
                  />
                </motion.div>
              )
          )}
        </AnimatePresence>

        {/* 추천사 내용 만큼의 height 영역을 차지하는 fake div */}
        <div className={cn('opacity-0')}>
          <TestimonialText
            content={content}
            contentAuthor={contentAuthor}
            contentAuthorOrganization={contentAuthorOrganization}
          />
        </div>
      </div>
    </div>
  )
}
