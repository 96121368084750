import type { GetLawyerProfileResponse } from '@/apis/lfind/lawyer-profile/fetchers'

export class LawyerMyCase {
  public readonly title: string
  public readonly docId: string
  public readonly court: string
  public readonly caseName: string

  constructor({ docId, title, court, caseName }: GetLawyerProfileResponse['lawyerMyCaseInfos'][number]) {
    this.title = title
    this.docId = docId
    this.court = court
    this.caseName = caseName
  }
}
