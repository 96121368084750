import { useEffect, useRef, useState } from 'react'

import Image from 'next/image'
import type { StaticImageData } from 'next/image'
import { useRouter } from 'next/router'

import { IconCaretLeft, IconCaretRight, withErrorBoundary } from '@lbox/shared/components'
import type { ValueOf } from '@lbox/shared/types'

import cn from 'classnames'
import type { PanInfo, Transition, Variants } from 'framer-motion'
import { AnimatePresence, motion } from 'framer-motion'

import { amplitudeTrack } from '@/utils/amplitude/amplitudeTrack'

export interface ImageItem {
  src: StaticImageData
  alt: string
  href?: string
  external?: boolean
  newTab?: boolean
  amplitude?: {
    eventName: string
    eventProperty?: Record<string, string>
  }
}

export interface BannerSliderProps {
  images: ImageItem[]
}

export type SwipeDirection = ValueOf<typeof SWIPE_DIRECTION>

const SWIPE_DIRECTION = {
  previous: -1,
  next: 1,
} as const

const SWIPE_THRESHOLD = 40

const sliderVariants: Variants = {
  start: (swipeDirection) => ({
    x: swipeDirection === SWIPE_DIRECTION.next ? '100%' : '-100%',
    opacity: 0,
  }),
  end: {
    x: 0,
    opacity: 1,
  },
  exit: (swipeDirection) => ({
    x: swipeDirection === SWIPE_DIRECTION.next ? '-100%' : '100%',
    opacity: 0,
  }),
}

const sliderTransition: Transition = {
  duration: 0.5,
  ease: [0.56, 0.03, 0.12, 1.04],
}

const BannerSlider = ({ images }: BannerSliderProps) => {
  const [[swipeIndex, swipeDirection], setSwipeInfo] = useState<[number, SwipeDirection]>([0, SWIPE_DIRECTION.next])
  const isTransitioningRef = useRef(false)
  const isDraggingRef = useRef(false)
  const router = useRouter()

  const totalImageCount = images.length
  const currentImageIndex = wrapNumber({ lower: 0, upper: totalImageCount - 1, value: swipeIndex })
  const currentImage = images[currentImageIndex]

  function swipeToImage(swipeDirection: SwipeDirection, forceSwipe = false) {
    if (forceSwipe || !isTransitioningRef.current) {
      setSwipeInfo([swipeIndex + swipeDirection, swipeDirection])
    }
  }

  function handleClickPreviousButton() {
    swipeToImage(SWIPE_DIRECTION.previous)
  }

  function handleClickNextButton() {
    swipeToImage(SWIPE_DIRECTION.next)
  }

  function handleDragEnd(dragInfo: PanInfo) {
    // drag end event 직후에 click 이벤트가 실행되는데 해당 click 이벤트를 무효화 하기 위한 로직
    isDraggingRef.current = true
    setTimeout(() => {
      isDraggingRef.current = false
    }, 100)

    const draggingDistance = dragInfo.offset.x

    if (draggingDistance < -SWIPE_THRESHOLD) {
      swipeToImage(SWIPE_DIRECTION.next, true)
    } else if (draggingDistance > SWIPE_THRESHOLD) {
      swipeToImage(SWIPE_DIRECTION.previous, true)
    }
  }

  function handleClickBannerImage() {
    if (!currentImage.href || isDraggingRef.current) {
      return
    }

    if (currentImage.amplitude) {
      amplitudeTrack(currentImage.amplitude.eventName, {
        ...currentImage.amplitude?.eventProperty,
        rank: currentImageIndex + 1,
      })
    }

    if (currentImage.newTab) {
      window.open(currentImage.href)
      return
    }

    if (currentImage.external) {
      window.location.assign(currentImage.href)
      return
    }

    router.push(currentImage.href)
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      swipeToImage(SWIPE_DIRECTION.next)
    }, 5000)

    return () => {
      clearTimeout(intervalId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentImageIndex])

  return (
    <div className={cn('relative', 'flex', 'overflow-x-hidden')}>
      {/* Image UI */}
      <AnimatePresence initial={false} custom={swipeDirection}>
        {/* absolute 요소 대신 영역을 차지해줄 요소 */}
        <div className={cn('w-[500px] max-w-full', 'aspect-[4/1]')} />
        <motion.div
          key={currentImageIndex}
          className={cn('absolute', 'w-full', 'hover:cursor-pointer active:cursor-grabbing')}
          variants={sliderVariants}
          custom={swipeDirection}
          initial="start"
          animate="end"
          exit="exit"
          transition={sliderTransition}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onAnimationStart={() => {
            isTransitioningRef.current = true
          }}
          onAnimationComplete={() => {
            isTransitioningRef.current = false
          }}
          onDragEnd={(_, dragInfo) => handleDragEnd(dragInfo)}
          onClick={handleClickBannerImage}
        >
          <Image
            src={currentImage.src}
            alt={currentImage.alt}
            priority
            className={cn('w-full', 'pointer-events-none')}
          />
        </motion.div>
      </AnimatePresence>
      {/* Controller UI */}
      <div
        className={cn(
          'absolute bottom-[calc(25.6%-16px)] left-[6.4%]',
          'flex items-center',
          'px-[4px] py-[2px]',
          'rounded-[8px]',
          'bg-[rgba(209,213,219,0.10)]',
          'text-lds2-body3-regular text-zinc-500',
          'whitespace-pre-wrap'
        )}
      >
        <button type="button" className={cn('outline-none')} onClick={handleClickPreviousButton}>
          <IconCaretLeft size={16} />
        </button>
        <span className={cn('text-black')}>{currentImageIndex + 1}</span>
        <span> / </span>
        {totalImageCount}
        <button type="button" className={cn('outline-none')} onClick={handleClickNextButton}>
          <IconCaretRight size={16} />
        </button>
      </div>
    </div>
  )
}

export default withErrorBoundary(BannerSlider, <></>)

interface WrapNumberParams {
  lower: number
  upper: number
  value: number
}

/**
 * 인자로 주어진 범위 내의 숫자 값만 반환한다
 * 주어진 숫자 범위는 inclusive(경계 포함) 하다
 *
 * 만약 value 값이 주어진 숫자 범위 내에 있다면,
 * value 를 그대로 반환한다
 *
 * 만약 value 값이 주어진 숫자 범위의 lower bound 를 벗어나면,
 * upper bound 에다가 (lower bound 를 벗어난 크기 - 1) 만큼을 빼서 반환한다
 *
 * 만약 value 값이 주어진 숫자 범위(start 부터 end 까지, inclusive)의 upper bound 를 벗어나면,
 * lower bound 에다가 (upper bound 를 벗어난 크기 - 1) 만큼을 더하여 반환한다
 *
 * @example
 * - lower: 0, upper: 5, value: 3 => 3
 * - lower: 0, upper: 5, value: 8 => 2
 * - lower: 0, upper: 5, value: 6 => 0
 * - lower: 0, upper: 5, value: 10 => 4
 * - lower: 0, upper: 5, value: -1 => 5
 * - lower: 0, upper: 5, value: -5 => 1
 * - lower: 3, upper: 7, value: 12 => 7
 */
function wrapNumber({ lower, upper, value }: WrapNumberParams) {
  if (lower > upper) {
    throw Error('upper bound 는 lower bound 이상이어야 합니다.')
  }

  if (lower <= value && value <= upper) {
    return value
  }

  const range = upper - lower + 1
  if (value < lower) {
    const diff = Math.abs(lower - value)
    const remainder = diff % range
    return remainder === 0 ? lower : upper - (remainder - 1)
  }

  const diff = Math.abs(upper - value)
  const remainder = diff % range
  return remainder === 0 ? upper : lower + (remainder - 1)
}
