import { useEffect } from 'react'

import { useRouter } from 'next/router'
import Script from 'next/script'

import { withErrorBoundary } from '@lbox/shared/components'
import { V2_PREFIX } from '@lbox/shared/constants'
import { useMediaQuery } from '@lbox/shared/hooks'

import { isRestrictedUser } from '@lbox-kr/components/src/utils/user/checkIsRestrictedUser'

import { postClickEtcEvent } from '@/apis/user/track/v2/postClickEtcEvent'
import { ENV } from '@/constants/env'
import { ZENDESK_CHAT_IFRAME_SELECTOR } from '@/constants/selector'
import { amplitudeTrack } from '@/utils/amplitude/amplitudeTrack'
import { getAmplitudePageType } from '@/utils/amplitude/getAmplitudePageType'

const ZENDESK_SCRIPT_SRC = 'https://static.zdassets.com/ekr/snippet.js?key=23b4e125-bbd3-4802-961c-192470919b10'

const ZendeskChatScript = () => {
  const router = useRouter()

  const { isDesktop } = useMediaQuery()

  useEffect(() => {
    const iframeElement = document.querySelector<HTMLIFrameElement>(ZENDESK_CHAT_IFRAME_SELECTOR)

    if (!iframeElement) {
      return
    }

    iframeElement.style.display = isDesktop ? '' : 'none'
  }, [isDesktop])

  useEffect(() => {
    return () => {
      const iframeElement = document.querySelector<HTMLIFrameElement>(ZENDESK_CHAT_IFRAME_SELECTOR)

      if (!iframeElement) {
        return
      }

      iframeElement.style.display = 'none'
    }
  }, [])

  const showsZendeskChat = ENV.NEXT_PUBLIC_ENV === 'production' && !isRestrictedUser() && isDesktop

  function postClickZendeskEventOnSRP() {
    // 검색결과 페이지에서 클릭한 경우 로깅
    const pathname = window.location.pathname
    if (pathname.replace(`/${V2_PREFIX}`, '').startsWith('/search')) {
      postClickEtcEvent({ type: 'click_zendesk' })
    }
  }

  return (
    <>
      {showsZendeskChat && (
        <Script
          id="ze-snippet"
          src={ZENDESK_SCRIPT_SRC}
          strategy="lazyOnload"
          onLoad={() => {
            setTimeout(() => {
              const iframeElement = document?.querySelector<HTMLIFrameElement>(ZENDESK_CHAT_IFRAME_SELECTOR)

              iframeElement?.contentWindow?.document.querySelector('button')?.addEventListener('click', () => {
                amplitudeTrack('click_zendesk', { page: getAmplitudePageType(router.pathname) })
                postClickZendeskEventOnSRP()
              })
            }, 2000)
          }}
        />
      )}
    </>
  )
}

export default withErrorBoundary(ZendeskChatScript, <></>)
