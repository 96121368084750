import cn from 'classnames'

import { MenuList } from './menu-list/MenuList'
import { LBoxLogo } from '../lbox-logo/LBoxLogo'

interface FooterProps {
  footerTagClassNames?: string
  hasBorder?: boolean
}

export const Footer = ({ footerTagClassNames, hasBorder = true }: FooterProps) => {
  return (
    <footer className={cn('w-full', 'p-[16px_16px_40px]', footerTagClassNames)}>
      <div className={cn('relative', 'w-full')}>
        <div
          className={cn('pt-[24px] tablet:pt-[16px]', {
            'border-t border-solid border-zinc-200': hasBorder,
          })}
        >
          <LBoxLogo />
          <MenuList />
        </div>
        <div className={cn('text-lds2-body3-regular text-zinc-600', 'my-[16px]')}>
          Copyright © {new Date().getFullYear()} LBOX Co., Ltd. All rights reserved.
        </div>
        <div className={cn('text-lds2-body3-regular text-zinc-400')}>
          상호명: 주식회사 엘박스 <br />
          사업자번호: 661-86-01360 <br />
          통신판매업신고번호: 2021-서울강남-03424 <br />
          주소: 서울특별시 강남구 테헤란로 306, 카이트타워 5층 <br />
          대표전화: 1670-1926 <br className={cn('hidden tablet:block')} /> 대표자명: 이진
        </div>
      </div>
    </footer>
  )
}
