import { useState } from 'react'

type AdvancedSearchType = '제외어' | '완전일치'

export const useTopFeatures = () => {
  const [selectedAdvancedSearchFeature, setSelectedAdvancedSearchFeature] = useState<AdvancedSearchType>('제외어')

  function selectAdvancedSearch(type: AdvancedSearchType) {
    setSelectedAdvancedSearchFeature(type)
  }

  return {
    selectedAdvancedSearchFeature,
    selectAdvancedSearch,
  }
}
