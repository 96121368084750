import type { ReactNode } from 'react'

import { withErrorBoundary } from '@lbox/shared/components'

import cn from 'classnames'

interface TestimonialTextProps {
  content: ReactNode
  contentAuthor: string
  contentAuthorOrganization: string
}

const TestimonialText = ({ content, contentAuthor, contentAuthorOrganization }: TestimonialTextProps) => {
  return (
    <div>
      <div
        className={cn('text-lds2-body2-regular text-zinc-900 tablet:text-lds2-heading4-medium tablet:leading-[34px]')}
      >
        {content}
      </div>
      <div className={cn('mt-[24px]', 'text-lds2-body3-regular')}>
        <p className={cn('text-zinc-900')}>{contentAuthor}</p>
        <p className={cn('text-zinc-500')}>{contentAuthorOrganization}</p>
      </div>
    </div>
  )
}
// NOTE: 간헐적으로 node를 못 찾는 에러 해소가 되지 않아서, 지역 error boundary 추가
export default withErrorBoundary(TestimonialText, <></>)
