import { ErrorBoundaryWithFallback } from '@lbox/shared/components'
import { LBOX_AI_PAGE_PATH, TIME, V2_PREFIX } from '@lbox/shared/constants'
import { useLocalStorage } from '@lbox/shared/hooks'

import { LAW_SCHOOL_LBOX_AI_POPUP_KEY } from '@lbox-kr/components/src/constants/localStorage'
import { EventModal } from '@lbox-kr/libra/v2'
import { useBooleanState } from '@toss/react'
import classNames from 'classnames'

import { useGetAIServiceWaitListStatus } from '@/apis/ai/waitlist/queries'
import lawschoolLboxAiPopupImage from '@/components/shared/home-popup/lawschool-lbox-ai-popup/$assets/lawschoolLboxAi.png'
import { amplitudeTrack } from '@/utils/amplitude/amplitudeTrack'

const UnprotectedLawschoolLboxAiPopup = () => {
  const { data: waitListData, isPending } = useGetAIServiceWaitListStatus({
    options: {
      throwOnError: true,
    },
  })

  const [isOpen, , close] = useBooleanState(true)
  const [date, setDate] = useLocalStorage(LAW_SCHOOL_LBOX_AI_POPUP_KEY, 0)

  if (isPending || waitListData?.status === 'APPROVED') {
    return null
  }

  if (!isOpen || Date.now() < date) {
    return null
  }

  return (
    <EventModal
      eventTracker={amplitudeTrack}
      eventName="LBOX AI 로스쿨 홍보 팝업"
      aspectRatio="1:1"
      imageSrc={lawschoolLboxAiPopupImage.src}
      imageAlt="LBOX AI 로스쿨 홍보 팝업 이미지"
      isOpen
      position="top"
      showsCloseButton
      closeIconClassName={classNames('group-enabled:fill-foreground-white')}
      onClose={close}
      onClick={() => {
        window.open(`/${V2_PREFIX}${LBOX_AI_PAGE_PATH.ai.landing.index}`)
        close()
      }}
    >
      <EventModal.Footer>
        <EventModal.Button
          onClick={() => {
            setDate(Date.now() + TIME['7일'])
            close()
          }}
        >
          일주일 동안 안 보기
        </EventModal.Button>
        <EventModal.Button onClick={close}>닫기</EventModal.Button>
      </EventModal.Footer>
    </EventModal>
  )
}

export const LawschoolLboxAiPopup = () => {
  return (
    <ErrorBoundaryWithFallback disableLogging fallback={<></>}>
      <UnprotectedLawschoolLboxAiPopup />
    </ErrorBoundaryWithFallback>
  )
}
