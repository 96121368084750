import { useGetUserHasPlan } from '@/apis/user-has-plan/queries'
import { LawschoolLboxAiPopup } from '@/components/shared/home-popup/lawschool-lbox-ai-popup/LawschoolLboxAiPopup'
import Lawyer20KCelebrationPopup from '@/components/shared/home-popup/lawyer-20k-celebration/Lawyer20KCelebrationPopup'

const HomePopup = () => {
  const { data: userHasPlanData } = useGetUserHasPlan()

  const userPlan = userHasPlanData?.plan
  const isLawSchoolPlan = userPlan === 'LAW_SCHOOL'

  return isLawSchoolPlan ? <LawschoolLboxAiPopup /> : <Lawyer20KCelebrationPopup />
}

export default HomePopup
