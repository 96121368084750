import type { FC, ReactNode } from 'react'

import { ErrorBoundaryWithFallback } from './ErrorBoundaryWithFallback'
import type { ErrorBoundaryFallbackProps } from './types'

/**
 * ErrorBoundary로 감싸는 역할을 하는 고차함수.
 * Component : 타겟 컴포넌트
 * fallback : ErrorFallback -> 인자 없을 시 null
 */
export const withErrorBoundary = <T extends object>(
  Component: FC<T>,
  fallback: FC<ErrorBoundaryFallbackProps> | ReactNode = null
) => {
  return function ComponentWithErrorBoundary(props: T) {
    return (
      <ErrorBoundaryWithFallback fallback={fallback}>
        <Component {...props} />
      </ErrorBoundaryWithFallback>
    )
  }
}
