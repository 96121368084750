import { useCallback, useRef, useSyncExternalStore } from 'react'

import { isEqual } from '@lbox/shared/utils'

import type { Storage } from '../../utils/storage/storage'

export const useStorage = <T>(key: string, defaultValue: T, storage: Storage) => {
  const memoizedValue = useRef<T | null>(null)

  const getSnapshot = useCallback(() => {
    const value = storage.getItem<T>(key, defaultValue)

    if (isEqual(memoizedValue.current, value)) {
      return memoizedValue.current as T
    }
    return (memoizedValue.current = value)
  }, [key, defaultValue, storage])

  const subscribe = useCallback((callback: VoidFunction) => {
    window.addEventListener('storage', callback)
    return () => window.removeEventListener('storage', callback)
  }, [])

  const value = useSyncExternalStore(subscribe, getSnapshot, getSnapshot)

  const setValue = useCallback(
    (newValue: T) => {
      storage.setItem(key, newValue)
      dispatchEvent(new StorageEvent('storage'))
    },
    [key, storage]
  )

  return [value, setValue] as const
}
