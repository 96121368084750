import Link from 'next/link'

import { Button } from '@lbox-kr/libra/v2'
import cn from 'classnames'

import { ContentWithTransition } from './content-with-transition/ContentWithTransition'

import { amplitudeTrack } from '@/utils/amplitude/amplitudeTrack'

export const Testimonial = () => {
  return (
    <div className={cn('flex flex-col items-center', 'tablet:w-[700px]', 'mx-auto')}>
      <ContentWithTransition />
      <div
        className={cn('flex justify-center', 'w-full', 'py-[8px]', 'mt-[32px]', 'border-t-[0.5px] border-t-zinc-300')}
      >
        <Button
          as={Link}
          href="https://lboxguide.oopy.io/partners"
          target="_blank"
          type="button"
          buttonType="ghost"
          label="고객사 전체보기"
          onClick={() => amplitudeTrack('click_main_partners')}
        />
      </div>
    </div>
  )
}
