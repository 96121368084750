import { useEffect } from 'react'
import type { PropsWithChildren } from 'react'

import { IconX, Portal } from '@lbox/shared/components'
import type { StrictPropsWithChildren } from '@lbox/shared/types'
import { noop } from '@lbox/shared/utils'

import cn from 'classnames'

import { twMergeLDS } from '../../../utils'
import { Backdrop } from '../backdrop'
import { IconOnlyButton } from '../button'

export interface EventModalProps {
  isOpen: boolean
  onClick?: VoidFunction
  onClose?: VoidFunction
  position?: 'top' | 'bottom' | 'center'
  aspectRatio: '3:4' | '1:1'
  imageSrc: string
  imageAlt: string
  showsCloseButton?: boolean
  closeIconClassName?: string
  eventName?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventTracker?: (eventType: string, eventProperties: Record<string, any>) => void
}

export const EventModal = ({
  children,
  isOpen,
  onClick,
  onClose = noop,
  position = 'top',
  aspectRatio,
  imageSrc,
  imageAlt,
  showsCloseButton = false,
  closeIconClassName,
  eventName,
  eventTracker,
}: PropsWithChildren<EventModalProps>) => {
  const handleClick = () => {
    if (!onClick) {
      return
    }

    if (!eventTracker || !eventName) {
      onClick()
      return
    }

    eventTracker('click_main_popup', {
      name: eventName,
    })

    onClick()
  }

  const handleClose = () => {
    if (!onClose) {
      return
    }

    if (!eventTracker || !eventName) {
      onClose()
      return
    }

    eventTracker('close_main_popup', {
      name: eventName,
    })

    onClose()
  }

  useEffect(() => {
    if (!isOpen || !eventTracker || !eventName) {
      return
    }

    eventTracker('view_main_popup', {
      name: eventName,
    })
  }, [isOpen])

  return (
    <Portal isOpen={isOpen} rootId="modal-root">
      <Backdrop
        isDimmed
        rootClassName={cn('block', {
          'lds2-tablet:hidden': position !== 'center',
          'lds2-tablet:block': position === 'center',
        })}
        onClick={handleClose}
      />
      <div
        role="dialog"
        aria-modal="true"
        className={cn('fixed w-[calc(100dvw-32px)] max-w-[400px] overflow-hidden rounded-[8px] shadow-lg', {
          'max-lds2-tablet:fixed-center lds2-tablet:right-[32px] lds2-tablet:top-[72px]': position === 'top',
          'max-lds2-tablet:fixed-center lds2-tablet:bottom-[32px] lds2-tablet:right-[32px]': position === 'bottom',
          'fixed-center': position === 'center',
        })}
      >
        <img
          src={imageSrc}
          alt={imageAlt}
          onClick={handleClick}
          className={cn('w-full object-cover object-top', {
            'aspect-square': aspectRatio === '1:1',
            'aspect-[3/4]': aspectRatio === '3:4',
            'cursor-pointer': Boolean(onClick),
          })}
        />
        {children}
        {showsCloseButton && (
          <span className={cn('absolute right-[8px] top-[8px]')}>
            <IconOnlyButton
              buttonType="ghost"
              size="medium"
              onClick={handleClose}
              icon={IconX}
              iconProps={{ className: closeIconClassName }}
            />
          </span>
        )}
      </div>
    </Portal>
  )
}

EventModal.Footer = function EventModalFooter({
  children,
  className,
}: StrictPropsWithChildren<{ className?: string }>) {
  return (
    <div className={twMergeLDS(cn('flex justify-between bg-background-primary px-[12px] py-[8px]'), className)}>
      {children}
    </div>
  )
}

EventModal.Button = function EventModalButton({
  children,
  onClick,
  className,
}: StrictPropsWithChildren<{ onClick: VoidFunction; className?: string }>) {
  return (
    <button
      type="button"
      className={twMergeLDS(
        cn('rounded-[4px] p-[8px] text-lds2-body2-regular text-text-tertiary interaction-state-high'),
        className
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
