import { V2_PREFIX } from '@lbox/shared/constants'
import { useMediaQuery } from '@lbox/shared/hooks'
import type { StrictPropsWithChildren } from '@lbox/shared/types'

import cn from 'classnames'

export const GrayGradientBox = ({ children }: StrictPropsWithChildren) => {
  const { isMobile } = useMediaQuery()

  return (
    <div
      style={{
        backgroundImage: `url("/${V2_PREFIX}/images/index/concentric-circles.svg"), radial-gradient(87.38% 87.38% at 50.00% 12.62%,#F4F6F9 0%,#E8EDF3 100%)`,
        backgroundSize: isMobile ? '940px, cover' : '1300px, cover',
        backgroundPosition: isMobile ? 'center -530px, center' : 'center -770px, center',
        backgroundRepeat: 'no-repeat',
      }}
      className={cn('p-[32px_32px_64px] tablet:p-[64px]', 'rounded-[12px]')}
    >
      {children}
    </div>
  )
}
