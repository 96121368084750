import type { ForwardedRef } from 'react'
import { forwardRef } from 'react'

import type { StrictPropsWithChildren } from '@lbox/shared/types'

import cn from 'classnames'

interface LogoButtonProps {
  onClick: VoidFunction
}

function _LogoButton(
  { children, onClick: handleClick }: StrictPropsWithChildren<LogoButtonProps>,
  ref: ForwardedRef<HTMLButtonElement>
) {
  return (
    <button
      ref={ref}
      type="button"
      className={cn(
        'relative',
        'flex items-center justify-center',
        'h-[64px] min-w-[155px] max-w-[155px]',
        'px-[8px] py-[16px]',
        'border-b-[0.5px] border-b-zinc-200 tablet:last-of-type:border-b-0'
      )}
      onClick={handleClick}
    >
      {children}
    </button>
  )
}

export const LogoButton = forwardRef(_LogoButton)
