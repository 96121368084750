import type { ReactNode } from 'react'
import { useState } from 'react'

import { IconX } from '@lbox/shared/components'
import { webLocalStorage } from '@lbox/shared/utils'

import useMe from '@lbox-kr/components/src/hooks/useMe'
import cn from 'classnames'
import { endOfDay, isBefore } from 'date-fns'

import { TOP_BANNER_NOT_SHOWN_UNTIL_KEY } from '@/constants/localStorage'

interface BannerProps {
  promotionText: string
  logoGroup?: ReactNode
  onClickBannerText?: VoidFunction
}

type TopBannerHidePeriodData = Record<string, string | null>

const TOP_BANNER_NOT_SHOWN_UNTIL_DEFAULT_USER_ID = 'guest'

export const Banner = ({ logoGroup, promotionText, onClickBannerText: handleClickBannerText }: BannerProps) => {
  const [isClosedByUserClick, setIsClosedByUserClick] = useState(false)

  const { userId } = useMe()

  const topBannerNotShownUntil = webLocalStorage.getItem<TopBannerHidePeriodData | null>(
    TOP_BANNER_NOT_SHOWN_UNTIL_KEY,
    null
  )
  const showsTopBanner = canShowTopBanner()

  function hideBanner() {
    setIsClosedByUserClick(true)
    updateTopBannerHidePeriod()
  }

  function canShowTopBanner() {
    if (!topBannerNotShownUntil || !(typeof topBannerNotShownUntil === 'object')) {
      return true
    }

    const topBannerNotShownUntilByUser = topBannerNotShownUntil[userId ?? TOP_BANNER_NOT_SHOWN_UNTIL_DEFAULT_USER_ID]
    if (topBannerNotShownUntilByUser && isBefore(new Date(), new Date(topBannerNotShownUntilByUser))) {
      return false
    }

    return true
  }

  function updateTopBannerHidePeriod() {
    const previousValue = webLocalStorage.getItem(TOP_BANNER_NOT_SHOWN_UNTIL_KEY, {})

    webLocalStorage.setItem(`${TOP_BANNER_NOT_SHOWN_UNTIL_KEY}`, {
      ...previousValue,
      [userId ?? TOP_BANNER_NOT_SHOWN_UNTIL_DEFAULT_USER_ID]: endOfDay(new Date()), // 하루 동안 보지 않기(그 날의 자정까지)
    })
  }

  return (
    <>
      {!isClosedByUserClick && showsTopBanner && (
        <div
          className={cn(
            'relative',
            'flex items-center justify-center',
            'py-3 pl-[20px] pr-[60px] tablet:pr-[88px]',
            'bg-[#EFEFEF]'
          )}
        >
          <div className={cn('flex flex-col items-center tablet:flex-row')}>
            {logoGroup && (
              <div className={cn('grid grid-cols-3 gap-2', 'mb-[12px] tablet:mb-0 tablet:mr-[18px]')}>{logoGroup}</div>
            )}
            <p
              className={cn('text-left text-lds2-body2-medium text-black', { 'cursor-pointer': handleClickBannerText })}
              onClick={handleClickBannerText}
            >
              {promotionText}
            </p>
          </div>
          <button
            type="button"
            className={cn(
              'absolute right-[8px] top-[8px] tablet:right-[32px] tablet:top-[50%] tablet:translate-y-[-50%]',
              'p-2'
            )}
            onClick={hideBanner}
          >
            <IconX size={24} weight="bold" />
          </button>
        </div>
      )}
    </>
  )
}
