import { ErrorBoundaryWithFallback } from '@lbox/shared/components'
import { LBOX_PAGE_PATH, V2_PREFIX } from '@lbox/shared/constants'

import { useGetPromotionsPeriods } from '@lbox-kr/components/src/apis/promotions/queries'
import cn from 'classnames'

import { useGetUserHasPlan } from '@/apis/user-has-plan/queries'
import type { ImageItem } from '@/components/shared/home-banner/banner-slider/BannerSlider'
import BannerSlider from '@/components/shared/home-banner/banner-slider/BannerSlider'

interface HomeBannerProps {
  bannerList: ImageItem[]
}

const UnprotectedHomeBanner = ({ bannerList }: HomeBannerProps) => {
  const { data: userHasPlanData } = useGetUserHasPlan()
  const { data: promotionPeriods } = useGetPromotionsPeriods({
    promotionName: 'LAWYER_GROWTH_20000',
  })

  const isLawyer20KEventPeriod = Boolean(promotionPeriods?.data.isEventInProgress)

  const userPlan = userHasPlanData?.plan
  const isLawSchoolPlan = userPlan === 'LAW_SCHOOL'

  const resultBannerList = isLawSchoolPlan
    ? bannerList.filter(
        ({ href }) => href !== `/${V2_PREFIX}${LBOX_PAGE_PATH.scholar.notice['노동조합법과-노사관계-실무']}`
      )
    : !isLawyer20KEventPeriod
      ? bannerList.filter(({ href }) => href !== `/${V2_PREFIX}${LBOX_PAGE_PATH.event.lawyer['20k-celebration']}`)
      : bannerList

  return (
    <div
      className={cn(
        'grid grid-cols-1 grid-rows-[1fr_fit-content(125px)_0] tablet:grid-rows-[1fr_max(125px)_1fr]',
        'grow'
      )}
    >
      {/* 가변 height 여백용 div */}
      <div className={cn('min-h-[48px]')} />
      <div className={cn('max-w-[calc(100vw-32px)]')}>
        <BannerSlider images={resultBannerList} />
      </div>
      {/* 가변 height 여백용 div */}
      <div />
    </div>
  )
}

const HomeBanner = (props: HomeBannerProps) => {
  return (
    <ErrorBoundaryWithFallback fallback={<></>} disableLogging>
      <UnprotectedHomeBanner {...props} />
    </ErrorBoundaryWithFallback>
  )
}

export default HomeBanner
